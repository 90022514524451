import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CampaignItemTarget} from "../dto/campaigns/campaignitemtarget";
import {CampaignItemTargetDetails} from "../dto/campaigns/campaignitemtargetdetails";
import {Observable} from "rxjs";

/**
 * service used to retrieve job target data
 */
@Injectable({
  providedIn: 'root'
})
export class TargetService {
  apiurl: string;

  constructor(@Inject('API_URL')apiurl: string, private http: HttpClient) {
    this.apiurl=apiurl;
  }

  /**
   * get job target data by specifying id of target
   * @param targetId id of campaign target
   */
  getItemById(targetId: number): Observable<CampaignItemTargetDetails> {
    return this.http.get<CampaignItemTargetDetails>(`${this.apiurl}/v1/targets/${targetId}`);
  }
}

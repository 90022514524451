import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeedFormat, FeedSourceData, FeedSourceService, SourceStatus, UpdateFrequency } from 'mamgo-api';

@Component({
  selector: 'app-source-creator',
  templateUrl: './source-creator.component.html',
  styleUrls: ['./source-creator.component.scss']
})
export class SourceCreatorComponent implements OnInit {
  @ViewChild('name') name: ElementRef;
  @ViewChild('url') url: ElementRef;

  format: FeedFormat=FeedFormat.Xml;
  frequency: UpdateFrequency=UpdateFrequency.Hourly;

  constructor(private dialog: MatDialogRef<SourceCreatorComponent>, private snackBar: MatSnackBar, private sourceService: FeedSourceService) { }

  ngOnInit(): void {
  }

  createSource(): void {
    let request: FeedSourceData={
      name: this.name.nativeElement.value,
      format: this.format,
      updateFrequency: this.frequency,
      url: this.url.nativeElement.value,

      description: "",
      predicate: "",
      status: SourceStatus.Inactive
    };

    this.sourceService.create(request)
    .toPromise()
    .then(()=>{
      this.dialog.close();
    })
    .catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }
}

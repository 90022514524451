<div class="pagecontent">
  <div class="titlecontainer">
    <h1>Campaign Performance - {{campaign.name}}</h1>
  </div>
  <div  class="container" *ngIf="isgenerating">
    <span>Generating Report</span>
    <div class="progressbox">
      <div class="progressbar" [style.width.%]="progress*100"></div>
    </div>
  </div>
  <div class="container mat-elevation-z8" *ngIf="!isgenerating">
    <button mat-raised-button color="primary" (click)="exportCsv()">CSV Export</button>
    <table mat-table [dataSource]="pageData" >
      <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
        <th mat-header-cell *matHeaderCellDef>{{column.display}}</th>
        <td mat-cell *matCellDef="let element">
          <div [ngSwitch]="column.name">
            <div *ngSwitchCase="'performance'" matTooltipClass="multilinetip" [matTooltip]="generatePerformanceTooltip(element)">
              <ng-container *ngFor="let filled of getPerformance(element.measured)">
                <img class="temperatureIcon" [ngClass]="{'prognosisIcon': element.progress<0.5}" [src]="'/assets/star-'+(filled?'filled':'empty')+'.png'" >
              </ng-container>
            </div>
            <div *ngSwitchCase="'temperatureLabel'" [matTooltip]="element.temperature | display : '2 Digits'">
              <ng-container *ngFor="let flame of getTemperature(element.temperatureLabel)">
                <img class="temperatureIcon" src="/assets/flame.png" *ngIf="flame">
                <img class="temperatureIcon" src="/assets/icecube.png" *ngIf="!flame">
              </ng-container>
            </div>
            <div *ngSwitchCase="'profit'" [matTooltip]="element.profit">
              <ng-container *ngFor="let x of getProfit(element.profit)">
                <img class="temperatureIcon" src="/assets/gold.png" *ngIf="x">
                <img class="temperatureIcon" src="/assets/poo.png" *ngIf="!x">
              </ng-container>
            </div>
            <span *ngSwitchDefault>{{element[column.name] | display:column.format}}</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="Tables.getColumns(columns)"></tr>
      <tr mat-row *matRowDef="let row; columns: Tables.getColumns(columns);"></tr>
    </table>
    <mat-paginator [pageIndex]="page" [length]="excellence.length" [pageSizeOptions]="Paging.pageSizes" (page)="onPageChanged($event.pageIndex, $event.pageSize)"></mat-paginator>
  </div>
</div>

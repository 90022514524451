/**
 * type of log entry
 */
export enum LogType {

    /**
     * informational log
     */
    Info=0,

    /**
     * warning
     */
    Warning=1,

    /**
     * validation error
     */
    Error=2
}
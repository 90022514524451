<div class="pagecontent">
    <div class="container">
        <h1>Jobs</h1>
        <mat-form-field class="input-full-width">
            <mat-label>Find</mat-label>
            <mat-icon class="prefixicon" matPrefix>search</mat-icon>
            <input matInput placeholder="Enter any detail to find" [(ngModel)]="filter.query" (onDebounce)="loadJobs()">
        </mat-form-field>
    </div>
    <div class="container mat-elevation-z8">
        <table mat-table [dataSource]="jobs" >
            <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
                <th mat-header-cell *matHeaderCellDef>{{column.display}}</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngSwitch]="column.name">
                        <div *ngSwitchCase="'_actions'">
                            <button mat-icon-button color="primary" (click)="showJob(element.id)">
                                <mat-icon>visibility</mat-icon>
                            </button>            
                        </div>
                        <span *ngSwitchDefault>{{Tables.getData(element, column.name) | display:column.format}}</span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="Tables.getColumns(columns)"></tr>
            <tr mat-row *matRowDef="let row; columns: Tables.getColumns(columns);"></tr>
        </table>
        <mat-paginator [pageIndex]="page" [length]="total" [pageSizeOptions]="Paging.pageSizes" (page)="onPageChanged($event.pageIndex, $event.pageSize)"></mat-paginator>
    </div>
</div>
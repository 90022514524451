import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationEntry } from '../dto/configuration/configurationentry';
import { ListFilter } from '../dto/listfilter';
import { Page } from '../dto/page';

/**
 * provides access to service configuration
 */
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private baseUrl: string;

  /**
   * creates a new {@link ConfigurationService}
   * @param apiurl url of mamgo-api
   * @param http http client used to communicate
   */
  constructor(@Inject('API_URL')apiurl: string, private http: HttpClient) { 
    this.baseUrl=`${apiurl}/v1/configuration`;
  }

  /**
   * get a configuration entry from system
   * @param key configuration key of entry to get
   */
  getValue(key: string) : Observable<ConfigurationEntry> {
    return this.http.get<ConfigurationEntry>(`${this.baseUrl}/${key}`);
  }

  /**
   * set a configuration value
   * @param key configuration key of entry to set
   * @param value value to set to entry
   */
  setValue(key: string, value: string) : Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${this.baseUrl}/${key}`, JSON.stringify(value), {
      headers: headers
    });
  }

  /**
   * lists a page of configuration entries
   * @param filter filter options
   */
  listPage(filter?: ListFilter): Observable<Page<ConfigurationEntry>> {
    let params=new HttpParams();
    if(filter) {
      for(let [key,value] of Object.entries(filter)) {
        if(!value)
          continue;
        params=params.append(key, value.toString().toLowerCase());
      }
    }

    return this.http.get<Page<ConfigurationEntry>>(this.baseUrl, {
      params: params
    });
  }

  /**
   * removes a configuration entry
   * @param key key of configuration entry to remove
   */
  removeEntry(key: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${key}`);
  }
}

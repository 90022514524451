<div class="pagecontent" *ngIf="source">
    <div class="titlecontainer">
        <h1>{{source.id}}: {{source.name}}</h1>
    </div>
    <div class="container mat-elevation-z8">
        <div class="row">
            <mat-form-field appearance="fill" class="field">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="source.name" (change)="changes=true">
            </mat-form-field>
            <mat-form-field appearance="fill" class="field">
                <mat-label>Format</mat-label>
                <mat-select [(ngModel)]="source.format" (selectionChange)="changes=true">
                    <mat-option value="Xml">Xml</mat-option>
                    <mat-option value="Json">Json</mat-option>
                    <mat-option value="Html">Html</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="field">
                <mat-label>Update Frequency</mat-label>
                <mat-select [(ngModel)]="source.updateFrequency" (selectionChange)="changes=true">
                    <mat-option value="None">None</mat-option>
                    <mat-option value="Hourly">Hourly</mat-option>
                    <mat-option value="HexaHourly">Every 6 Hours</mat-option>
                    <mat-option value="HalfDaily">Every 12 Hours</mat-option>
                    <mat-option value="Daily">Every other Day</mat-option>
                    <mat-option value="Weekly">Every other Week</mat-option>
                    <mat-option value="Monthly">Every other Month</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="field">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]="source.status" (selectionChange)="changes=true">
                    <mat-option value="Inactive">Inactive</mat-option>
                    <mat-option value="Active">Active</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Url</mat-label>
                <input matInput [(ngModel)]="source.url" (change)="changes=true">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="textarea" appearance="fill">
                <mat-label>Description</mat-label>
                <textarea class="textblock" matInput [(ngModel)]="source.description" (change)="changes=true"></textarea>
            </mat-form-field>
            <mat-form-field class="textarea" appearance="fill">
                <mat-label>Predicate</mat-label>
                <textarea class="textblock" matInput [(ngModel)]="source.predicate" (change)="changes=true"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="container">
        <button mat-raised-button color="primary" [disabled]="!changes" (click)="saveSource()">Save</button>
    </div>
</div>

<div class="pagecontent" *ngIf="!source">
    <div class="container">
        <h2>
            Loading Feed Source Data
            <img class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </h2>
    </div>
</div>

import {Injectable} from "@angular/core";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImageCacheService {
  cache: Map<string, SafeUrl>=new Map<string, SafeUrl>();
  clients: Map<string, ((url: SafeUrl)=>void)[]>=new Map<string, ((url: SafeUrl)=>void)[]>();

  constructor(private sanitizer: DomSanitizer) {
  }

  getImage(key: string, assignment: (url: SafeUrl)=>void, loader: ()=>Observable<any>): void {
    let safeUrl: SafeUrl=this.cache.get(key);
    if(safeUrl)
    {
      assignment(safeUrl);
      return;
    }

    let callbacks=this.clients.get(key);
    if(callbacks)
    {
      callbacks.push(assignment);
      return;
    }
    else {
      console.log("creating new callback list")
      this.clients.set(key, []);
    }

    loader().subscribe(image=>{
      const loadedUrl: SafeUrl=this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(image));
      this.cache.set(key, loadedUrl);
      assignment(loadedUrl);
      for(let client of this.clients.get(key))
        client(loadedUrl);
      this.clients.delete(key);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { FeedSource, FeedSourceData, FeedSourceService, Patch } from 'mamgo-api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-source-editor',
  templateUrl: './source-editor.component.html',
  styleUrls: ['./source-editor.component.scss']
})
export class SourceEditorComponent implements OnInit {
  sourceId: number;

  original: FeedSource;
  source: FeedSource;

  changes:boolean=false;

  constructor(private sourceService: FeedSourceService, route: ActivatedRoute, private snackBar: MatSnackBar) { 
    this.sourceId=route.snapshot.params.sourceId;
  }

  ngOnInit(): void {
    this.loadSource();
  }

  private handleSourceData(source: Observable<FeedSource>): void {
    source.toPromise()
    .then(s=>{
      this.original=Object.assign({}, s);
      this.source=s;
      this.changes=false;
    })
    .catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  loadSource(): void {
    this.handleSourceData(this.sourceService.getById(this.sourceId));
  }

  saveSource(): void {
    this.handleSourceData(this.sourceService.patch(this.sourceId, Patch.generatePatches(this.original, this.source)));
  }
}

import {TableData} from "../../models/table-data";
import {CampaignWarningDetails, CampaignListFilter} from "mamgo-api";

export class CampaignWarningData extends TableData<CampaignWarningDetails, CampaignListFilter> {

  constructor() {
    super([{
      name: "campaignName",
      display: "Campaign"
    }, {
      name: "brokerName",
      display: "Broker"
    }, {
      name: "type",
      display: "type"
    }, {
      name: "level",
      display: "Level"
    }], {
      count: 25,
      continue: 0
    });
  }
}

import { HttpParams } from '@angular/common/http';

/**
 * provides helper methods for rest interaction
 */
export class Rest {

  static queryValue(value: any, casing: boolean=false): string {
    if(value instanceof Date) {
      const day = value.getDate();
      const month = value.getMonth() + 1;
      const year = value.getFullYear();
      return `${year}-${month}-${day}`;
    }

    if(casing)
      return value.toString();
    return value.toString().toLowerCase();
  }

  /**
   * creates http parameters from a filter class
   * @param filter filter to convert to parameters
   * @param casing if true, casing of string values is preserved
   */
  public static createParameters(filter: any, casing: boolean=false): HttpParams {
    if(!filter)
      return null;

    let params=new HttpParams();

    for(const [key,value] of Object.entries(filter)) {
      if(Array.isArray(value)) {
        for(const item of value) {
          if(item===null||item===undefined)
            continue;
          params=params.append(key.toLowerCase(), Rest.queryValue(item, casing));
        }
      }
      else {
        if(value!==null&&value!==undefined)
          params=params.append(key.toLowerCase(), Rest.queryValue(value, casing));
      }
    }

    return params;
  }
}

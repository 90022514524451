import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ClickEventData} from "../dto/jobs/clicks/click-event-data";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class JobAdService {

  constructor(@Inject('API_URL')private apiUrl: string, private http: HttpClient) {
  }

  /**
   * registers an event for a target
   * @param targetId id of target for which to register event
   * @param data event data to register
   */
  registerEvent(targetId: number, data: ClickEventData): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/jobads/${targetId}`, data);
  }
}

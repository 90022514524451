<h2>Create Source</h2>
<mat-form-field appearance="fill" class="input-full-width">
    <mat-label>Name</mat-label>
    <input matInput #name>
</mat-form-field>
<mat-form-field appearance="fill" class="input-full-width">
    <mat-label>Format</mat-label>
    <mat-select [(ngModel)]="format">
        <mat-option value="Xml">Xml</mat-option>
        <mat-option value="Json">Json</mat-option>
        <mat-option value="Html">Html</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field appearance="fill" class="input-full-width">
    <mat-label>Update Frequency</mat-label>
    <mat-select [(ngModel)]="frequency">
        <mat-option value="None">None</mat-option>
        <mat-option value="Hourly">Hourly</mat-option>
        <mat-option value="HexaHourly">Every 6 Hours</mat-option>
        <mat-option value="HalfDaily">Every 12 Hours</mat-option>
        <mat-option value="Daily">Every other Day</mat-option>
        <mat-option value="Weekly">Every other Week</mat-option>
        <mat-option value="Monthly">Every other Month</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field appearance="fill" class="input-full-width">
    <mat-label>Feed Url</mat-label>
    <input matInput #url>
</mat-form-field>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="createSource()">Create</button>
</mat-dialog-actions>

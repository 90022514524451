<div class="page">
  <mat-sidenav-container fullscreen>
    <mat-sidenav #sidenav [opened]="isOpen" *ngIf="isLoggedIn()" (closedStart)="isOpen=false">
      <mat-nav-list>
        <h2 matSubheader> {{ 'Business' }} </h2>
        <a mat-list-item routerLink="/" (click)="sidenav.close()">Dashboard</a>
        <a mat-list-item routerLink="/sources" (click)="sidenav.close()">Sources</a>
        <a mat-list-item routerLink="/campaigns" (click)="sidenav.close()">Campaigns</a>
        <a mat-list-item routerLink="/analysis" (click)="sidenav.close()">Analysis</a>
        <a mat-list-item routerLink="/reports" (click)="sidenav.close()">Reports</a>
        <mat-divider></mat-divider>
        <h2 matSubheader> {{ 'System' }} </h2>
        <a mat-list-item routerLink="/jobs" (click)="sidenav.close()">Jobs</a>
        <a mat-list-item routerLink="/brokers" (click)="sidenav.close()">Brokers</a>
        <a mat-list-item routerLink="/facts" (click)="sidenav.close()">Facts</a>
        <a mat-list-item routerLink="/synonyms" (click)="sidenav.close()">Synonyms</a>
        <mat-divider></mat-divider>
        <h2 matSubheader> {{ 'Dev' }} </h2>
        <a mat-list-item routerLink="/configuration" (click)="sidenav.close()">Configuration</a>
        <a mat-list-item routerLink="/database" (click)="sidenav.close()">Database</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="primary" *ngIf="isLoggedIn()">
        <mat-toolbar-row>
          <img class="logo" src="/assets/mamgo-icon.png" (click)="isOpen=!isOpen">
          <h1>Mamgo</h1>
        </mat-toolbar-row>
      </mat-toolbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

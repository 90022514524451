import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthToken } from '../dto/auth/authtoken';
import { User } from '../dto/auth/user';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  user: User;

  constructor(private authservice: AuthService) {
    const data=localStorage.getItem("user");
    if(data!==null)
      this.user=JSON.parse(data);
  }

  login(username: string, password: string): Promise<User> {
    return this.authservice.getToken(username, password).then(t=>{
        return this.handleUserResponse(t);
      });
  }

  getUser(): User {
    return this.user;
  }

  refresh(): Observable<User> {
    if(!this.user)
      return;

    const token=this.user.refresh;

    if(Date.now()<this.user.refreshexpires) {
      return this.authservice.refreshToken(token).pipe(map(t=>{
        return this.handleUserResponse(t);
      }));
    } else {
      this.logout();
    }
  }

  logout(): void {
    localStorage.removeItem("user");
    this.user=null;
  }

  private handleUserResponse(response: AuthToken): User {
    let userobject=JSON.parse(atob(response.access_token.split('.')[1]));
    this.user={
      token: response.access_token,
      name: userobject.name,
      roles: userobject.roles,
      refresh: response.refresh_token,
      expires: Date.now()+response.expires_in*800,
      refreshexpires: Date.now()+response.refresh_expires_in*1000,
      expires_in:response.expires_in
    };
    localStorage.setItem("user", JSON.stringify(this.user));
    return this.user;
  }
}

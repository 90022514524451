/**
 * status of a targeted job item in a campaign
 */
export enum JobItemStatus {

    /**
     * broker is not targeted currently
     */
    BrokerBlocked=-3,

    /**
     * quotas have been met (eg. budget limit hit)
     */
    HitQuota=-2,

    /**
     * job was posted but got canceled due to expiration or manual cancellation
     */
    Canceled=-1,

    /**
     * job has not been posted yet
     */
    Unposted=0,

    /**
     * job should get posted and will be included in generated job feeds
     */
    Posted=1,

    
}
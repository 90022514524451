import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { JobFilter, JobListItem, JobService } from 'mamgo-api';
import { Paging } from '../../helpers/paging';
import { Tables } from '../../helpers/tables';
import { Column } from '../../models/column';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  Paging=Paging;
  Tables=Tables;

  page: number=0;
  total: number=0;
  filter: JobFilter={
    count: 25,
    continue: 0,
    isActive: true
  };

  jobs: MatTableDataSource<JobListItem>=new MatTableDataSource<JobListItem>();

  columns: Column[]=[{
    name: "title",
    display: "Title"
  }, {
    name: "date",
    display: "Date",
    format: "Date"
  }, {
    name: "location.country",
    display: "Country"
  }, {
    name: "location.city",
    display: "City"
  }, {
    name: "company.name",
    display: "Company"
  }, {
    name: "_actions",
    display: ""
  }];

  constructor(private jobService: JobService, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.loadJobs();
  }

  loadJobs(): void {
    this.jobService.list(this.filter).toPromise().then(page=>{
      this.total=page.total;
      this.page=this.filter.continue/this.filter.count;
      this.jobs.data=page.result;
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  showJob(jobId: number): void {
    this.router.navigateByUrl(`/jobs/${jobId}`);
  }

  onPageChanged(page: number, size: number): void {
    this.filter.continue=page*size;
    this.filter.count=size;
    this.loadJobs();
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CampaignWarningFilter} from "../dto/warnings/campaign-warning-filter";
import {Observable} from "rxjs";
import {Page} from "../dto/page";
import {CampaignWarningDetails} from "../dto/warnings/campaign-warning-details";
import {Rest} from "../helpers/rest";

@Injectable({
  providedIn: 'root'
})
export class CampaignWarningService {

  baseUrl: string;

  /**
   * creates a new {@link CampaignWarningService}
   * @param apiUrl api url of backend
   * @param http service used to send requests
   */
  constructor(@Inject('API_URL')apiUrl: string, private http: HttpClient) {
    this.baseUrl=`${apiUrl}/v1/warnings`
  }

  /**
   * lists warnings using a filter
   * @param filter filter to apply
   * @return warnings which match the filter
   */
  list(filter?: CampaignWarningFilter) : Observable<CampaignWarningDetails[]> {
    return this.http.get<CampaignWarningDetails[]>(this.baseUrl, {
      params: Rest.createParameters(filter)
    });
  }
}

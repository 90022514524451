import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { User } from '../dto/auth/user';
import {flatMap} from "rxjs/internal/operators";

/**
 * adds the current token to the authorization header
 * if the current request points to the auth url the interceptor logic is skipped
 * when the token is about to expire this automatically gets the refresh token and uses
 * the new token
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(@Inject('AUTH_URL')private authurl: string, private loginservice: LoginService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(request.url.startsWith(this.authurl))
            return next.handle(request);

        const user: User=this.loginservice.getUser();
        if(user)
        {
            if(Date.now()>user.expires){
                return this.loginservice.refresh()
                  .pipe(flatMap(u=>{
                    return next.handle(request.clone({
                      headers: request.headers.set("Authorization", `Bearer ${u.token}`)
                    }));
                  }));
            }
            else {
                return next.handle(request.clone({
                    headers: request.headers.set("Authorization", `Bearer ${user.token}`)
                }));
            }
        }

        return next.handle(request);
    }
}

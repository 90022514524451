/**
 * job validation result status
 */
export enum JobDataStatus {

    /**
     * invalid or missing info in job entry
     */
    InvalidInfo=-2,

    /**
     * job needs to be validated
     */
    ValidationPending=0,

    /**
     * job can be posted but can get optimized
     */
    Suboptimal=1,

    /**
     * job is valid and can get posted
     */
    Valid=2
}
import {MatTableDataSource} from "@angular/material/table";
import {Column} from "./column";
import {ListFilter, Page} from "mamgo-api";

/**
 * base class for data displayed in a table
 */
export class TableData<TData,TFilter extends ListFilter> {
  page: number=1;
  pages: number=0;

  filterData: TFilter;
  private tableData: MatTableDataSource<TData>=new MatTableDataSource<TData>();
  private columnData: Column[];

  constructor(columns: Column[], filter: TFilter) {
    this.columnData=columns;
    this.filterData=filter;
    console.log(this.columnData);
  }

  setPage(pageIndex: number, pageSize: number): void {
    this.page=pageIndex;
    this.filterData.count=pageSize;
  }

  setResultPage(page: Page<TData>) : void {
    this.pages=page.total/this.filterData.count+1;
    this.page=this.filterData.continue/this.filterData.count+1;
    this.tableData.data=page.result;
  }

  setResultData(data: TData[]) : void {
    this.pages=0;
    this.page=0;
    this.tableData.data=data;
  }

  get columns(): Column[] {
    return this.columnData;
  }

  get items(): TData[] {
    return this.tableData.data;
  }

  set items(data: TData[]) {
    this.tableData.data=data;
  }

  get dataSource(): MatTableDataSource<TData>{
    return this.tableData;
  }
}

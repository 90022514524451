
/**
 * grouping by
 */
export enum PerformanceWindowType {

    /**
     * performance time is not grouped, all results are returned as such
     */
    None=0,

    /**
     * performance items are grouped by hour of day
     */
    Hour,

    /**
     * performance items are grouped by day of year
     */
    Day,

    /**
     * performance items are grouped by weekday of week
     */
    Weekday,

    /**
     * performance items are grouped by week of year
     */
    Week,

    /**
     * performance items are grouped by month of year
     */
    Month,

    /**
     * all entries are grouped to a single result
     */
    Full=255,
}
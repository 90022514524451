import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ItemClickPerformance} from '../dto/performance/itemclickperformance';
import {PerformanceEntry} from '../dto/performance/performanceentry';
import {PerformanceFilter} from '../dto/performance/performancefilter';
import {PerformanceFilterV2} from '../dto/performance/performancefilterv2';
import {Rest} from "../helpers/rest";
import {ReportFormat} from "../dto/performance/reportFormat";

/**
 * service used to retrieve performance data
 */
@Injectable({
  providedIn: 'root'
})
export class PerformanceService {
  apiurl: string;
  constructor(@Inject('API_URL')apiurl: string, private http: HttpClient) {
    this.apiurl=apiurl;
  }

  /**
   * get performance based on job page visits
   * @param filter criterias for evaluation
   */
  page(filter: PerformanceFilter) : Observable<ItemClickPerformance[]> {
    let params=new HttpParams();
    if(filter) {
      for(let [key,value] of Object.entries(filter)) {
        params=params.append(key, value.toString().toLowerCase());
      }
    }

    return this.http.get<ItemClickPerformance[]>(`${this.apiurl}/v1/performance`, {
      params: params
    });
  }

  /**
   * get click performance
   * @param filter criterias for evaluation
   */
  clicks(filter: PerformanceFilterV2) : Observable<PerformanceEntry[]> {
    if(filter&&filter.format)
      filter.format=ReportFormat.Json;

    return this.http.get<PerformanceEntry[]>(`${this.apiurl}/v2/performance/clicks`, {
      params: Rest.createParameters(filter)
    });
  }

  /**
   * download report from api
   * @param filter filter specifying report data
   */
  report(filter: PerformanceFilterV2): Observable<Blob> {
    return this.http.get(`${this.apiurl}/v2/performance/report`, {
      params: Rest.createParameters(filter),
      responseType: 'blob',
    });
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ListFilter} from "../dto/listfilter";
import {Observable} from "rxjs";
import {CampaignKpis} from "../dto/performance/campaign-kpis";
import {Page} from "../dto/page";
import {Rest} from "../helpers/rest";
import {CampaignKpiFilter} from "../dto/campaigns/campaign-kpi-filter";

@Injectable({
  providedIn: 'root'
})
export class CampaignKpiService {
  baseUrl: string;

  /**
   * creates a new {@link CampaignKpiService}
   * @param apiUrl api url of backend
   * @param http service used to send requests
   */
  constructor(@Inject('API_URL')apiUrl: string, private http: HttpClient) {
    this.baseUrl=`${apiUrl}/v1/kpis/campaigns`
  }

  /**
   * computes kpis for campaigns
   * @param filter filter to apply
   */
  computeCampaignKpis(filter: CampaignKpiFilter): Observable<Page<CampaignKpis>> {
    return this.http.get<Page<CampaignKpis>>(this.baseUrl, {
      params: Rest.createParameters(filter)
    });
  }
}

<div class="pagecontent">
  <div class="container">
    <h1>Campaign Kpis</h1>
  </div>
  <div class="container mat-elevation-z8">
    <select (change)="onMonthChange($event.target.value)">
      <option *ngFor="let option of getKpiMonths()" [value]="option|display:'Date'" [selected]="option.getFullYear()===campaigns.filterData.month.getFullYear() && option.getMonth()===campaigns.filterData.month.getMonth()">{{option|display:'Date':'yyyy-MM'}}</option>
    </select>
    <table mat-table [dataSource]="campaigns.dataSource" >
      <ng-container [matColumnDef]="column.name" *ngFor="let column of campaigns.columns">
        <th mat-header-cell *matHeaderCellDef>{{column.display}}</th>
        <td mat-cell *matCellDef="let element">
            <span>{{element[column.name] | display:column.format}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="Tables.getColumns(campaigns.columns)"></tr>
      <tr mat-row *matRowDef="let row; columns: Tables.getColumns(campaigns.columns);"></tr>
    </table>
    <mat-paginator
      [pageIndex]="campaigns.page"
      [length]="campaigns.items.length"
      [pageSizeOptions]="Paging.pageSizes"
      (page)="onPageChanged($event.pageIndex, $event.pageSize)">
    </mat-paginator>
  </div>
  <div class="container">
    <h1>Campaign Warnings</h1>
  </div>
  <div class="container mat-elevation-z8">
    <table mat-table [dataSource]="warnings.dataSource" >
      <ng-container [matColumnDef]="column.name" *ngFor="let column of warnings.columns">
        <th mat-header-cell *matHeaderCellDef>{{column.display}}</th>
        <td mat-cell *matCellDef="let element">
          <div [ngSwitch]="column.name">
            <div *ngSwitchCase="'_actions'"></div>
            <span *ngSwitchDefault>{{element[column.name] | display:column.format}}</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="Tables.getColumns(warnings.columns)"></tr>
      <tr mat-row *matRowDef="let row; columns: Tables.getColumns(warnings.columns);"></tr>
    </table>
  </div>
</div>

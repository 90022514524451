import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {flatMap, map, mergeMap, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private baseUrl: string;

  constructor(@Inject('API_URL')apiurl: string, private http: HttpClient) {
    this.baseUrl=`${apiurl}/v1/documents`;
  }

  /**
   * get a document or its meta data by id
   * @param documentId id of document to return
   */
  getDocumentById(documentId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/${documentId}`, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(mergeMap((response: HttpResponse<object>)=>{
      let jsonIndex1=response.headers.get("content-type")?.indexOf("/json");
      let jsonIndex2=response.headers.get("Content-Type")?.indexOf("/json");
      if(jsonIndex1>-1||jsonIndex2>-1) {
        if (typeof response.body === 'string')
          return JSON.parse(<string><any>response.body);

        return new Observable<string>(observer => {
          const reader = new FileReader();
          reader.onload = (e) => {
            let responseText = (<any>e.target).result;

            observer.next(responseText);
            observer.complete();
          }
          reader.readAsText(<Blob>response.body, 'utf-8');
        }).pipe(map(data => {
          return JSON.parse(data);
        }));
      }
      return of(response.body);
    }));
  }
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { JobService, JobStatusDetails, ValidationEntry } from 'mamgo-api';
import { Tables } from '../../helpers/tables';
import { Column } from '../../models/column';

@Component({
  selector: 'app-job-status-log',
  templateUrl: './job-status-log.component.html',
  styleUrls: ['./job-status-log.component.scss']
})
export class JobStatusLogComponent implements OnInit {
  Tables=Tables;

  brokerId: number;
  jobId: number;

  status: JobStatusDetails;
  log: MatTableDataSource<ValidationEntry>=new MatTableDataSource<ValidationEntry>();

  columns: Column[]=[{
    name: "time",
    display: "Time",
    format: "Date"
  }, {
    name: "source",
    display: "Source"
  }, {
    name: "field",
    display: "Field"
  }, {
    name: "message",
    display: "Message"
  }];

  constructor(private jobService: JobService, route: ActivatedRoute, private snackBar: MatSnackBar) { 
    this.jobId=route.snapshot.params.jobId;
    this.brokerId=route.snapshot.params.brokerId;
  }

  ngOnInit(): void {
    this.loadJobLog();
  }

  loadJobLog(): void {
    this.jobService.getStatus(this.jobId, this.brokerId)
    .toPromise().then(s=>{
      this.status=s;
      this.log.data=s.log;
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CampaignItemFilter} from "../dto/campaigns/campaignItemFilter";
import {Observable} from "rxjs";
import {Page} from "../dto/page";
import {CampaignItemTargetDetails} from "../dto/campaigns/campaignitemtargetdetails";
import {Rest} from "../helpers/rest";

@Injectable({
  providedIn: 'root'
})
export class CampaignItemService {

  /**
   * creates a new {@link CampaignItemService}
   * @param apiUrl url to api root
   * @param http http client to use for http calls
   */
  constructor(@Inject('API_URL')private apiUrl: string, private http: HttpClient) { }

  /**
   * lists broker specific items of campaigns
   * @param filter filter to apply
   */
  list(filter?: CampaignItemFilter): Observable<Page<CampaignItemTargetDetails>> {
    return this.http.get<Page<CampaignItemTargetDetails>>(this.apiUrl+"/v1/campaignitemtargets", {
      params: Rest.createParameters(filter, true)
    });
  }
}

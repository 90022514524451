/**
 * class of click event
 * special patterns detected by system
 */
export enum ClickEventFlags {

  /**
   * no special attributes
   */
  None,

  /**
   * real event but repeated by the same origin
   */
  Duplicate,

  /**
   * event which seems to originate by some script
   */
  Bot,

  /**
   * event was reported by broker but not measured by system
   */
  Fake,

  /**
   * event was registered in system but not reported by broker
   * also it doesn't look like a bot or duplicate behavior
   */
  Magic,

  /**
   * click not paid for
   */
  Organic=8,

  /**
   * event deviates in known information (cpc value)
   */
  Ignorant=16,

  /**
   * filter to use when determining click class
   */
  Filter_Class=7
}

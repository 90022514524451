/**
 * format to use when getting data from api
 * not all formats are supported by every endpoint (xls is only used in reports)
 */
export enum ReportFormat {
  /**
   * default format used by api
   */
  Json,

  /**
   * csv representation of data
   */
  Csv,

  /**
   * result table in excel format
   */
  Excel
}

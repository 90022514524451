<div class="pagecontent" *ngIf="campaign">
    <div class="titlecontainer">
        <h1>Campaign {{campaign.id}}: {{campaign.name}}</h1>
    </div>
    <div class="container mat-elevation-z8">
        <div class="row">
          <mat-form-field class="property" appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="campaign.name" (ngModelChange)="onCampaignChanged()">
          </mat-form-field>
          <mat-form-field class="property" appearance="fill">
            <mat-label>Budget</mat-label>
            <input matInput [(ngModel)]="campaign.budget" (ngModelChange)="onCampaignChanged()">
          </mat-form-field>
          <mat-form-field class="property" appearance="fill">
            <mat-label>CPA</mat-label>
            <input matInput [(ngModel)]="campaign.cpa" (ngModelChange)="onCampaignChanged()">
          </mat-form-field>
          <mat-form-field class="property" appearance="fill">
            <mat-label>CCR</mat-label>
            <input matInput [(ngModel)]="campaign.ccr" (ngModelChange)="onCampaignChanged()">
          </mat-form-field>
          <mat-form-field class="property" appearance="fill">
            <mat-label>Status</mat-label>
            <input matInput [(ngModel)]="campaign.status" (ngModelChange)="onCampaignChanged()">
          </mat-form-field>
        </div>
        <div class="row">
          <div class="property">
            <mat-checkbox [(ngModel)]="campaign.useMamgoLandingPages" (ngModelChange)="onCampaignChanged()" [disabled]="campaign.useJobUfo">Use Mamgo Landing Pages</mat-checkbox>
            <br/>
            <mat-checkbox [(ngModel)]="campaign.useJobUfo" (ngModelChange)="onCampaignChanged()">Use Job Ufo</mat-checkbox>
          </div>
          <mat-form-field class="property" appearance="fill">
            <mat-label>Custom ApplyUrl</mat-label>
            <input matInput [(ngModel)]="campaign.applyUrlPattern" (ngModelChange)="onCampaignChanged()">
          </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="descriptionfield property" appearance="fill">
                <mat-label>Description</mat-label>
                <textarea class="description" matInput [(ngModel)]="campaign.description" (ngModelChange)="onCampaignChanged()"></textarea>
            </mat-form-field>
            <mat-form-field class="predicatefield property" appearance="fill">
                <mat-label>Predicate</mat-label>
                <textarea class="predicate" matInput [(ngModel)]="campaign.predicate" (ngModelChange)="onCampaignChanged()"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="savecontainer">
        <button mat-raised-button color="primary" [disabled]="!changes" (click)="save()">Save</button>
    </div>
    <div class="container mat-elevation-z8">
        <div class="brokers">
            <h3>Targeted Brokers</h3>
            <table>
                <tr *ngFor="let broker of allbrokers">
                    <mat-checkbox [checked]="isBrokerSelected(broker.id)" (change)="selectBroker(broker.id, $event.checked)">{{broker.name}}</mat-checkbox>
                </tr>
            </table>
        </div>
        <div class="sources">
            <h3>Connected Sources</h3>
            <mat-form-field class="find" appearance="fill">
                <input matInput type="text" placeholder="Find and add Source" [matAutocomplete]="auto" (change)="findSources($event.target.value)" #searchInput>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addSource($event.option.value)">
                    <mat-option *ngFor="let sourceoption of sourceoptions" [value]="sourceoption.id">
                        {{sourceoption.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <table>
                <tr *ngFor="let source of sources">
                    <td>{{source.name}}</td>
                    <td><mat-icon>filter_list</mat-icon></td>
                    <td>
                        <button mat-icon-button (click)="removeSource(source.sourceId)" type="button">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<div class="pagecontent" *ngIf="!campaign">
    <div class="titlecontainer">
        <h2>
            Loading Campaign Data
            <img class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </h2>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Broker, BrokerService } from 'mamgo-api';
import { Tables } from '../../helpers/tables';
import { Column } from '../../models/column';

@Component({
  selector: 'app-brokers',
  templateUrl: './brokers.component.html',
  styleUrls: ['./brokers.component.scss']
})
export class BrokersComponent implements OnInit {
  Tables=Tables;

  brokers: MatTableDataSource<Broker>=new MatTableDataSource<Broker>();
  columns: Column[]=[{
    name: "name",
    display: "Name"
  }, {
    name: "_actions",
    display: ""
  }];

  constructor(private brokerService: BrokerService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.loadBrokers();
  }

  loadBrokers(): void {
    this.brokerService.listBrokers().toPromise().then(b=>{
      this.brokers.data=b;
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  showFeed(broker: Broker): void {
    window.open(this.brokerService.generateFeedLink(broker.moniker), "_blank");
  }
}

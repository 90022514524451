import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthToken } from '../dto/auth/authtoken';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authurl: string;
  clientid: string;
  clientsecret: string;

  constructor(@Inject('AUTH_URL')authurl: string, @Inject('CLIENT_ID')clientid: string, @Inject('CLIENT_SECRET')clientsecret: string,private http: HttpClient) {
    this.authurl=authurl;
    this.clientid=clientid;
    this.clientsecret=clientsecret;
  }

  getToken(username: string, password: string): Promise<AuthToken> {
    let body=new URLSearchParams();
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    body.set("grant_type", "password");
    body.set("username", username);
    body.set("password", password);
    body.set("client_id", this.clientid);
    body.set("client_secret", this.clientsecret);

    return this.http.post<AuthToken>(this.authurl, body.toString(), options).toPromise();
  }

  refreshToken(token: string): Observable<AuthToken> {
    let body=new URLSearchParams();
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    body.set("grant_type", "refresh_token");
    body.set("refresh_token", token);
    body.set("client_id", this.clientid);
    body.set("client_secret", this.clientsecret);

    return this.http.post<AuthToken>(this.authurl, body.toString(), options);
  }
}

<div class="pagecontent">
    <div class="container">
        <h1>Feed Sources</h1>
    </div>
    <div class="container mat-elevation-z8">
        <table mat-table [dataSource]="sources" >
            <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
                <th mat-header-cell *matHeaderCellDef>{{column.display}}</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngSwitch]="column.name">
                        <mat-slide-toggle *ngSwitchCase="'status'" [checked]="element.status==='Active'" (change)="changeStatus(element)">{{element.status}}</mat-slide-toggle>
                        <div *ngSwitchCase="'_actions'">
                            <a [routerLink]="element.id"><mat-icon>visibility</mat-icon></a>
                        </div>
                        <span *ngSwitchDefault>{{element[column.name] | display:column.format}}</span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
            <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
        </table>
        <mat-toolbar>
            <mat-toolbar-row>
                <button mat-icon-button color="primary" (click)="createSource()">
                    <mat-icon class="actionicon">add_box</mat-icon>
                </button>
                <mat-paginator class="paginator" [pageIndex]="page" [length]="total" [pageSizeOptions]="Paging.pageSizes" (page)="onPageChanged($event.pageIndex, $event.pageSize)"></mat-paginator>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>

/**
 * status of document
 */
export enum DocumentStatus {

  /**
   * document is available for download
   */
  Available,

  /**
   * system is processing the document
   */
  Processing,

  /**
   * there was an error processing the document
   */
  Error
}

<div class="pagecontent" *ngIf="job">
    <div class="titlecontainer">
        <h1>{{job.id}}: {{job.title}}</h1>
    </div>
    <div class="container mat-elevation-z8">
        <div class="row">
            <mamgo-property class="property" name="Date" [value]="job.date | display:'Date'"></mamgo-property>
            <mamgo-property class="property" name="Reference" [value]="job.reference | display"></mamgo-property>
            <mamgo-property class="property" name="Type" [value]="job.jobType | display"></mamgo-property>
            <mamgo-property class="property" name="Publisher" [value]="job.publisher?.name | display"></mamgo-property>
            <mamgo-property class="property" name="Company" [value]="job.company?.name | display"></mamgo-property>
        </div>
        <div class="row">
            <mamgo-property class="property" name="Country" [value]="job.location?.country | display"></mamgo-property>
            <mamgo-property class="property" name="State" [value]="job.location?.state | display"></mamgo-property>
            <mamgo-property class="property" name="City" [value]="job.location?.city | display"></mamgo-property>
            <mamgo-property class="property" name="Postal Code" [value]="job.location?.postalCode | display"></mamgo-property>
            <mamgo-property class="property" name="Line" [value]="job.location?.line | display"></mamgo-property>
        </div>
        <div class="row">
            <mamgo-property class="property" name="Salary" [value]="job.salary | display"></mamgo-property>
            <mamgo-property class="property" name="Experience" [value]="job.experience | display"></mamgo-property>
            <mamgo-property class="property" name="Education" [value]="job.education | display"></mamgo-property>
            <mamgo-property class="property" name="Categories" [value]="job.categories | display"></mamgo-property>
        </div>
        <div class="row">
            <mamgo-property class="property joburl" name="Url" [value]="job.url" type="url"></mamgo-property>
        </div>
    </div>
    <div class="container mat-elevation-z8">
        <div class="content-item">
            <h3>Broker Compatibility</h3>
            <mat-chip-list aria-label="Broker Compatibility">
                <mat-chip (click)="showJobLog(job.id, broker.id)" [class.statusvalid]="broker.status==='Valid'" [class.statussuboptimal]="broker.status==='Suboptimal'" [class.statusinvalid]="broker.status==='InvalidInfo'" *ngFor="let broker of job.brokers">{{broker.name}}</mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div class="container mat-elevation-z8">
        <div class="row">
            <mamgo-property class="fulltext" name="Description" [value]="job.description" type="html"></mamgo-property>
        </div>
    </div>
    <div class="container mat-elevation-z8">
        <div class="row">
            <mamgo-property class="fulltext" name="Requirements" [value]="job.requirements" type="html"></mamgo-property>
        </div>
    </div>
    <div class="container mat-elevation-z8">
        <div class="row">
            <mamgo-property class="fulltext" name="Offers" [value]="job.offers" type="html"></mamgo-property>
        </div>
    </div>
</div>

<div class="pagecontent" *ngIf="!job">
    <div class="container">
        <h2>
            Loading Job Details
            <img class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </h2>
    </div>
</div>
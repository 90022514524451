import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Fact } from '../dto/facts/fact';
import { FactData } from '../dto/facts/factdata';
import { ListFilter } from '../dto/listfilter';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class FactsService extends RestService<Fact, Fact, FactData, ListFilter>{

  constructor(@Inject('API_URL')apiurl: string, http: HttpClient) { 
    super(`${apiurl}/v1/facts`, http) 
  }


}

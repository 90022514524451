/**
 * type of item to analyse
 */
export enum PerformanceItemType {
  
  /**
   * job targeted at broker
   */
  Target,

  /**
   * job
   */
  Job

  /**
   * campaign
   */
  ,
  Campaign

  /**
   * broker
   */
  ,
  Broker
}

/**
 * status of source
 */
export enum SourceStatus {
        /*
        * source is not active currently
        */
        Inactive=0,
        
        /*
        * source is active, will get updated (if frequency!=0) and can get used in campaigns
        */
        Active=1
}

export namespace SourceStatus {
        export function getName(type: any): string {
            if(typeof type === "number")
                return SourceStatus[type];
    
            if(typeof type === "string")
            {
                let num=parseInt(type)
                if(num>=0)
                    return SourceStatus[num];
            }
    
            return type.toString();
        }
    
        export function getValue(type: any): SourceStatus {
            if(type as string) {
                let num=parseInt(type);
                if(num>=0)
                    return num;
                return SourceStatus[type as string];
            }
            return type;
        }
    }
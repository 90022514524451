import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ExcellenceFilter} from "../dto/performance/excellence-filter";
import {Observable} from "rxjs";
import {Rest} from "../helpers/rest";

@Injectable({
  providedIn: 'root'
})
export class ExcellenceService {
  private baseUrl: string;

  constructor(@Inject('API_URL')apiurl: string, private http: HttpClient) {
    this.baseUrl=`${apiurl}/v1/performance/excellence`;
  }

  /**
   * determines excellence values for performance items
   * @param filter filter for excellence operation to execute
   */
  determineExcellence(filter: ExcellenceFilter) : Observable<any> {
    return this.http.get<any>(this.baseUrl, {
      params: Rest.createParameters(filter)
    });
  }
}

/**
 * status of a job campaign
 */
export enum CampaignStatus {
    
    /**
     * campaign was created but contained jobs are not targeted
     */
    Created=0,

    /**
     * campaign is online
     */
    Online=1,

    /**
     * campaign is offline and jobs are not targeted anymore
     */
    Offline=2,

    /**
     * campaign is archived and is not visible without explicit request anymore
     */
    Archived=3
}
import {TableData} from "../../models/table-data";
import {CampaignKpis, CampaignKpiFilter} from "mamgo-api";

export class CampaignKpiData extends TableData<CampaignKpis, CampaignKpiFilter> {

  constructor() {
    super([{
        name: "campaignName",
        display: "Name"
      }, {
        name: "applications",
        display: "Applications"
      }, {
        name: "currentDelta",
        display: "Pace",
        format: "Currency"
      }, {
        name: "monthlyDelta",
        display: "Budget",
        format: "Currency"
      }, {
        name: "bill",
        display: "Bill",
        format: "Currency"
      }, {
        name: "profit",
        display: "Profit (with Rebate)",
        format: "Currency"
      }], {
      count: 25,
      continue: 0
      }
    );
  }
}

/**
 * available performance item grouping types
 */
export enum PerformanceItemGroup {

    /**
     * groups by job broker
     */
    Broker=0,

    /**
     * groups by campaign
     */
    Campaign=1,

    /**
     * groups by campaign item
     */
    Item=2,

    /**
     * groups by cpc value
     */
    Cpc=3,

    /**
     * groups by targeted campaign item
     */
    Target=4,

    /**
     * group by date
     */
    Date=5,

    /**
     * group by job
     */
    Job=6,

    /**
     * group by year in which data was registered
     */
    Year=7,

    /**
     * group by month in which data was registered
     */
    Month=8,

    /**
     * group by day of month
     */
    DayOfMonth=9,

    /**
     * group by hour of day
     */
    Hour=10,

    /**
     * group by day of year
     */
    DayOfYear=11,

    /**
     * group by day of week
     */
    DayOfWeek=12,

    /**
     * company which provides the job
     */
    Company=13,

    /**
     * country where click originated
     */
    Country=14
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'mamgo-api';
import { LoginComponent } from './auth/login/login.component';
import { AnalysisComponent } from './business/analysis/analysis.component';
import { CampaignEditorComponent } from './business/campaign-editor/campaign-editor.component';
import { CampaignsComponent } from './business/campaigns/campaigns.component';
import { DashboardComponent } from './business/dashboard/dashboard.component';
import { SourceEditorComponent } from './business/source-editor/source-editor.component';
import { SourcesComponent } from './business/sources/sources.component';
import { SynonymsComponent } from './business/synonyms/synonyms.component';
import { ConfigurationComponent } from './dev/configuration/configuration.component';
import { DatabaseComponent } from './dev/database/database.component';
import { BrokersComponent } from './system/brokers/brokers.component';
import { FactsComponent } from './system/facts/facts.component';
import { JobDetailsComponent } from './system/job-details/job-details.component';
import { JobStatusLogComponent } from './system/job-status-log/job-status-log.component';
import { JobsComponent } from './system/jobs/jobs.component';
import {ReportComponent} from "./business/report/report.component";
import {CampaignViewerComponent} from "./business/campaign-viewer/campaign-viewer.component";

const routes: Routes = [
  {path:'', redirectTo: '/dashboard', pathMatch: 'full'},
  {path:'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path:'sources', component: SourcesComponent, canActivate: [AuthGuard]},
  {path:'sources/:sourceId', component: SourceEditorComponent, canActivate: [AuthGuard]},
  {path:'campaigns', component: CampaignsComponent, canActivate: [AuthGuard]},
  {path:'campaigns/:campaignId', component: CampaignEditorComponent, canActivate: [AuthGuard]},
  {path:'campaigns/:campaignId/excellence', component: CampaignViewerComponent, canActivate: [AuthGuard]},
  {path:'analysis', component: AnalysisComponent, canActivate: [AuthGuard]},
  {path:'reports', component: ReportComponent, canActivate:[AuthGuard]},
  {path:'configuration', component: ConfigurationComponent, canActivate: [AuthGuard]},
  {path:'database', component: DatabaseComponent, canActivate: [AuthGuard]},
  {path:'jobs', component: JobsComponent, canActivate: [AuthGuard]},
  {path:'jobs/:jobId', component: JobDetailsComponent, canActivate: [AuthGuard]},
  {path:'jobs/:jobId/status/:brokerId', component: JobStatusLogComponent, canActivate: [AuthGuard]},
  {path:'facts', component: FactsComponent, canActivate: [AuthGuard]},
  {path:'brokers', component: BrokersComponent, canActivate: [AuthGuard]},
  {path:'synonyms', component: SynonymsComponent, canActivate: [AuthGuard]},
  {path:'login', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

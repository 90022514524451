import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Fact, FactsService, ListFilter } from 'mamgo-api';
import { Paging} from '../../helpers/paging';
import { Tables } from '../../helpers/tables';
import { Column } from '../../models/column';

@Component({
  selector: 'app-facts',
  templateUrl: './facts.component.html',
  styleUrls: ['./facts.component.scss']
})
export class FactsComponent implements OnInit {
  Paging=Paging;
  Tables=Tables;

  page: number=0;
  total: number=0;
  filter: ListFilter={
    count: 25,
    continue: 0
  };

  facts: MatTableDataSource<Fact>=new MatTableDataSource<Fact>();

  columns: Column[]=[{
    name: "id",
    display: "Id"
  }, {
    name: "name",
    display: "Name"
  }, {
    name: "value",
    display: "Value"
  }];

  constructor(private factService: FactsService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.loadFacts();
  }

  loadFacts(): void {
    this.factService.list(this.filter).toPromise()
      .then(page=>{
        this.total=page.total;
        this.page=this.filter.continue/this.filter.count;
        this.facts.data=page.result;
      }).catch(e=>{
        this.snackBar.open(e.error.text);
      });
  }

  onPageChanged(page: number, size: number): void {
    this.filter.continue=page*size;
    this.filter.count=size;
    this.loadFacts();
  }

}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SynonymService, Synonym, SynonymFilter } from 'mamgo-api';
import { Paging} from '../../helpers/paging';
import { Tables } from '../../helpers/tables';
import { Column } from '../../models/column';

@Component({
  selector: 'app-synonyms',
  templateUrl: './synonyms.component.html',
  styleUrls: ['./synonyms.component.scss']
})
export class SynonymsComponent implements OnInit {
  Paging=Paging;
  Tables=Tables;
  page: number=0; 
  total: number=0;
  filter: SynonymFilter={
    count: 25,
    continue: 0
  };

  synonyms: MatTableDataSource<Synonym>=new MatTableDataSource<Synonym>();

  columns: Column[]=[{
    name: "context",
    display: "Kontext"
  }, {
    name: "literal",
    display: "Begriff"
  }, {
    name: "translation",
    display: "Übersetzung"
  },
  {
    name: "parent",
    display: "Zugehörigkeit"
  }];

  constructor(private synonymService: SynonymService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.loadSynonyms();
  }
  
  loadSynonyms(): void {
    this.synonymService.list(this.filter).toPromise()
      .then(page=>{
        this.total=page.total;
        this.page=this.filter.continue/this.filter.count;
        this.synonyms.data=page.result;
      }).catch(e=>{
        this.snackBar.open(e.error.text);
      });
  }

  onPageChanged(page: number, size: number): void {
    this.filter.continue=page*size;
    this.filter.count=size;
    this.loadSynonyms();
  }

}

/**
 * known job operation times
 */
export enum JobType {

    /**
     * job type is not specified
     */
    Unknown=-1,

    /**
     * job is posted as full time
     */
    Fulltime=0,

    /**
     * job is posted as part time
     */
    Parttime=1
}
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Synonym } from '../dto/synonyms/synonym';
import { SynonymData } from '../dto/synonyms/synonymdata';
import { SynonymFilter } from '../dto/synonyms/synonymfilter';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class SynonymService extends RestService<Synonym, Synonym, SynonymData, SynonymFilter> {

  constructor(@Inject('API_URL')apiurl: string, http: HttpClient) {
    super(`${apiurl}/v1/synonyms`, http);
  }

  /**
   * uploads synonym data to import
   * @param data data to upload
   */
  upload(data: Blob) : Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/data`, data);
  }
}
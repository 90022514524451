import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FeedSource, FeedSourceService, Patch } from 'mamgo-api';
import { ListFilter, SourceStatus } from 'projects/mamgo-api/src/public-api';
import { Paging } from '../../helpers/paging';
import { Column } from '../../models/column';
import { SourceCreatorComponent } from '../source-creator/source-creator.component';

@Component({
  selector: 'app-sources',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss']
})
export class SourcesComponent implements OnInit {
  Paging=Paging;

  sources: MatTableDataSource<FeedSource>=new MatTableDataSource<FeedSource>();

  page: number=1;
  total:number=0;
  filter: ListFilter={
    count: 25,
    continue: 0
  };

  columns: Column[]=[{
    name: "name",
    display: "Name"
  }, {
    name: "format",
    display: "Format"
  }, {
    name: "updateFrequency",
    display: "Frequency"
  }, {
    name: "lastRetrieval",
    display: "Last Update",
    format: "Date"
  }, {
    name: "jobs",
    display: "# of Jobs"
  }, {
    name: "failures",
    display: "# of Failures"
  }, {
    name: "status",
    display: "Status"
  }, {
    name: "_actions",
    display: ""
  }];

  constructor(private sourceService: FeedSourceService, private snackBar: MatSnackBar, private dialogservice: MatDialog) { }

  ngOnInit(): void {
    this.loadSources();
  }

  loadSources(): void {
    this.sourceService.list(this.filter).toPromise().then(page => {
      this.page = this.filter.continue / this.filter.count;
      this.total = page.total;
      this.sources.data = page.result;
    }).catch(error => {
      this.snackBar.open(error.error.text);
    });
  }

  getDisplayedColumns(): string[] {
    return this.columns.map<string>(c=>c.name);
  }

  changeStatus(source: FeedSource): void {
    let newvalue: SourceStatus;
    if(SourceStatus.getValue(source.status)===SourceStatus.Active)
      newvalue=SourceStatus.Inactive;
    else newvalue=SourceStatus.Active;

    this.sourceService.patch(source.id, [
      Patch.replace("status", newvalue)
    ]).toPromise().then(s=>{
      let index=this.sources.data.findIndex(src=>src.id===source.id);
      if(index>=0)
      {
        this.sources.data[index]=s;

        // looks silly, but the data source refreshes on assignment
        this.sources.data=this.sources.data;
      }
    }).catch(error=>{
      this.snackBar.open(error.error.text);
    });
  }

  createSource(): void {
    this.dialogservice.open(SourceCreatorComponent).afterClosed().subscribe(e=>{
      this.loadSources();
    });
  }

  onPageChanged(pageIndex: number, pageSize: number): void {
    this.filter.continue=pageIndex*pageSize;
    this.filter.count=pageSize;
    this.loadSources();
  }
}

<div class="pagecontent">
    <div class="container">
        <h1>Database</h1>
        <mat-form-field class="statementinput">
            <mat-label>Query</mat-label>
            <textarea matInput placeholder="Statement Text to send to Database" [(ngModel)]="statement"></textarea>
            <mat-icon class="prefixicon" matSuffix (click)="sendStatement()">search</mat-icon>
        </mat-form-field>
    </div>
    <div class="container mat-elevation-z8" *ngIf="columns">
        <table mat-table [dataSource]="resulttable" >
            <ng-container [matColumnDef]="column" *ngFor="let column of columns">
                <th mat-header-cell *matHeaderCellDef>{{column}}</th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element[column]}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
    </div>
</div>
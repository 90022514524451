import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DocumentService, AnalyticsService, PerformanceService, BrokerService, CampaignService, Broker, Campaign, PerformanceFilterV2, PerformanceItemGroup, ReportFormat, CampaignStatus} from "mamgo-api";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Errors} from "../../helpers/Errors";
import {DocumentDownloadService} from "../../helpers/document-download.service";

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  ReportFormat=ReportFormat;

  reportType: string="Clicks";
  filter: PerformanceFilterV2={
    format: ReportFormat.Csv
  };
  generating: boolean=false;
  progress: number=0.0;

  brokers: Broker[]=[];
  campaigns: Campaign[]=[];

  groups=[{
      group: PerformanceItemGroup.Broker,
      display: "Broker"
    },
    {
      group: PerformanceItemGroup.Campaign,
      display: "Campaign"
    },
    {
      group: PerformanceItemGroup.Job,
      display: "Job"
    },
    {
      group: PerformanceItemGroup.Cpc,
      display: "Cpc"
    },
    {
      group: PerformanceItemGroup.Date,
      display: "Date"
    },
    {
      group: PerformanceItemGroup.DayOfMonth,
      display: "Day of Month"
    },
    {
      group: PerformanceItemGroup.DayOfWeek,
      display: "Weekday"
    },
    {
      group: PerformanceItemGroup.Hour,
      display: "Hour of Day"
    },
    {
      group: PerformanceItemGroup.Month,
      display: "Month"
    }
  ];

  details=[{
    name: "job.title",
    display: "Job Title",
    active: false
  },{
    name: "job.description",
    display: "Job Description",
    active: false
  },{
    name: "job.category",
    display: "Job Category",
    active: false
  },{
    name: "job.type",
    display: "Job Type",
    active: false
  },{
    name: "job.salary",
    display: "Job Salary",
    active: false
  },{
    name: "job.reference",
    display: "Job Reference",
    active: false
  },{
    name: "job.date",
    display: "Job Original Post Date (feed)",
    active: false
  },{
    name: "target.status",
    display: "Job Post Status",
    active: false
  },{
    name: "campaign.name",
    display: "Name of Campaign",
    active: false
  },{
    name: "broker.name",
    display: "Name of Broker",
    active: false
  }];

  constructor(private brokerService: BrokerService,
              private campaignService: CampaignService,
              private analyticsService: AnalyticsService,
              private documentService: DocumentDownloadService,
              private snackBar: MatSnackBar,
              private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loadBrokers();
    this.loadCampaigns();
  }

  private loadCampaigns(): void {
    this.campaignService.list({
      status: CampaignStatus.Online
    }).toPromise()
      .then(c=>{
        this.campaigns=c.result
      })
      .catch(e=>{
        this.snackBar.open(Errors.getError(e));
      });
  }

  private loadBrokers(): void {
    this.brokerService.listBrokers().toPromise()
      .then(b=>{
        this.brokers=b;
      })
      .catch(e=>{
        this.snackBar.open(Errors.getError(e));
      });
  }

  hasGrouping(group: PerformanceItemGroup): boolean {
    if(!this.filter.group)
      return false;

    const index=this.filter.group.findIndex(g => g === group);
    return index > -1;
  }

  toggleGrouping(group: PerformanceItemGroup) : void {
    if(!this.filter.group)
      this.filter.group=[];

    const index = this.filter.group.findIndex(g => g === group);
    if (index > -1) {
      this.filter.group.splice(index, 1);
      switch(group) {
        case PerformanceItemGroup.Job:
          for(let detail of this.details) {
            if (detail.name.startsWith("job."))
              detail.active = false;
            if(detail.name.startsWith("target"))
              detail.active=false;
          }
          break;
        case PerformanceItemGroup.Broker:
          for(let detail of this.details)
            if(detail.name.startsWith("broker."))
              detail.active=false;
          break;
        case PerformanceItemGroup.Campaign:
          for(let detail of this.details)
            if(detail.name.startsWith("campaign."))
              detail.active=false;
          break;
      }
    }
    else {
      this.filter.group.push(group);
      switch(group) {
        case PerformanceItemGroup.Job:
          for(let detail of this.details) {
            if (detail.name.startsWith("job."))
              detail.active = true;
            if(detail.name.startsWith("target."))
              detail.active=true;
          }
          break;
        case PerformanceItemGroup.Broker:
          for(let detail of this.details)
            if(detail.name.startsWith("broker."))
              detail.active=true;
          break;
        case PerformanceItemGroup.Campaign:
          for(let detail of this.details)
            if(detail.name.startsWith("campaign."))
              detail.active=true;
          break;
      }
    }
    this.changeDetector.markForCheck();
  }

  hasBroker(brokerId: number): boolean {
    if(!this.filter.brokerId)
      return false;
    return this.filter.brokerId && this.filter.brokerId.findIndex(b => b === brokerId) > -1;
  }

  toggleBroker(brokerId: number): void {
    if(!this.filter.brokerId)
      this.filter.brokerId=[];

    const index = this.filter.brokerId.findIndex(g => g === brokerId);
    if (index > -1)
      this.filter.brokerId.splice(index, 1);
    else this.filter.brokerId.push(brokerId);
    this.changeDetector.markForCheck();
  }

  hasCampaign(campaignId: number): boolean {
    return this.filter.campaignId && this.filter.campaignId.findIndex(b => b === campaignId) > -1;
  }

  toggleCampaign(campaignId: number): void {
    if(!this.filter.campaignId)
      this.filter.campaignId=[];

    const index = this.filter.campaignId.findIndex(g => g === campaignId);
    if (index > -1)
      this.filter.campaignId.splice(index, 1);
    else this.filter.campaignId.push(campaignId);
    this.changeDetector.markForCheck();
  }

  hasDetail(detail: string): boolean {
    return this.filter.detail && this.filter.detail.findIndex(b => b === detail) > -1;
  }

  toggleDetail(detail: string): void {
    if(!this.filter.detail)
      this.filter.detail=[];
    const index = this.filter.detail.findIndex(g => g === detail);
    if (index > -1)
      this.filter.detail.splice(index, 1);
    else this.filter.detail.push(detail);
    this.changeDetector.markForCheck();
  }

  generateFilename(): string {
    let filename: string="mamgo-report_";
    filename+=this.filter.from.getFullYear()+"-"+(this.filter.from.getMonth()+1)+"-"+this.filter.from.getDate();
    if(this.filter.to)
      filename+="_"+this.filter.to.getFullYear()+"-"+(this.filter.to.getMonth()+1)+"-"+this.filter.to.getDate();
    switch(this.filter.format) {
      case ReportFormat.Json:
        return filename+".json";
      case ReportFormat.Csv:
        return filename+".csv";
      case ReportFormat.Excel:
        return filename+".xlsx";
    }
    return filename;
  }

  generateReport(): void {
    this.generating=true;
    this.progress=0.0;

    if(this.reportType==='Clicks') {
      this.analyticsService.generateReport(this.filter)
        .toPromise()
        .then(report => {
          this.documentService.downloadDocument(report, this.generateFilename(), p => {
            this.progress = p;
            if (p >= 1.0) {
              this.generating = false;
            }
          });
        })
        .catch(e => {
          this.snackBar.open(Errors.getError(e));
          this.generating = false;
        });
    }
    else {
      this.analyticsService.generateJobPostingReport(this.filter)
        .toPromise()
        .then(report => {
          this.documentService.downloadDocument(report, this.generateFilename(), p => {
            this.progress = p;
            if (p >= 1.0) {
              this.generating = false;
            }
          });
        })
        .catch(e => {
          this.snackBar.open(Errors.getError(e));
          this.generating = false;
        });
    }
  }
}

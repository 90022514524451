import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Broker } from '../dto/brokers/broker';

/**
 * manages job broker data
 */
@Injectable({
  providedIn: 'root'
})
export class BrokerService {
  private baseUrl: string;

  constructor(@Inject('API_URL')apiurl: string, private http: HttpClient) { 
    this.baseUrl=`${apiurl}/v1/brokers`;
  }

  /**
   * lists available brokers
   */
  listBrokers(): Observable<Broker[]> {
    return this.http.get<Broker[]>(this.baseUrl);
  }

  /**
   * get job feed formatted for specified broker
   * @param broker moniker identifying broker
   */
  getFeed(broker: string) : Observable<any> {
    return this.http.get(this.generateFeedLink(broker));
  }

  /**
   * generates a link to a job feed to be provided to brokers
   * @param broker moniker identifying broker
   */
  generateFeedLink(broker: string): string {
    return `${this.baseUrl}/${broker}/feed`;
  }  
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Broker, BrokerService, Campaign, CampaignDetails, CampaignService, FeedSource, Patch, CampaignSourceData, FeedSourceService } from 'mamgo-api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-campaign-editor',
  templateUrl: './campaign-editor.component.html',
  styleUrls: ['./campaign-editor.component.scss']
})
export class CampaignEditorComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;

  campaignId: number;

  original: Campaign;
  campaign: Campaign=null;

  allbrokers: Broker[]=[];
  brokers: Broker[]=[];
  sources: CampaignSourceData[]=[];

  sourceoptions: FeedSource[]=[];

  changes: boolean;

  constructor(private campaignService: CampaignService, private brokerService: BrokerService,
              private sourceService: FeedSourceService, route: ActivatedRoute, private snackBar: MatSnackBar) {
    this.campaignId=route.snapshot.params.campaignId;
  }

  ngOnInit(): void {
    this.loadBrokers();
    this.findSources(null);
    this.loadCampaign();
  }

  private handleCampaignRequest(request: Observable<CampaignDetails>): void {
    request.toPromise().then(c=>{
      this.updateCampaign(c);
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  private updateCampaign(campaign: CampaignDetails): void {
    this.campaign={
      id: campaign.id,
      name: campaign.name,
      budget: campaign.budget,
      status: campaign.status,
      description: campaign.description,
      predicate: campaign.predicate,
      budgetFactor: campaign.budgetFactor,
      jobSelection: campaign.jobSelection,
      cpa: campaign.cpa,
      ccr: campaign.ccr,
      useMamgoLandingPages: campaign.useMamgoLandingPages,
      useJobUfo: campaign.useJobUfo,
      applyUrlPattern: campaign.applyUrlPattern
    };

    this.original=Object.assign({}, campaign);
    this.brokers=campaign.brokers;
    this.sources=campaign.sources;
  }

  onCampaignChanged() : void {
    if(this.campaign.useJobUfo)
      this.campaign.useMamgoLandingPages=true;
    this.changes=true;
  }

  save() : void {
    this.handleCampaignRequest(
      this.campaignService.patch(
        this.campaignId,
        Patch.generatePatches(this.original, this.campaign)
    ));
    this.changes=false;
  }

  loadCampaign() : void {
    this.handleCampaignRequest(this.campaignService.getById(this.campaignId, {
      detail: ["broker", "source"]
    }));
  }

  loadBrokers(): void {
    this.brokerService.listBrokers().toPromise().then(b=>{
      this.allbrokers=b;
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  /**
   * determines whether the specified broker is currently targeted in the current campaign
   * @param brokerId id of broker to check for
   */
  isBrokerSelected(brokerId: number): boolean {
    if(!this.campaign || !this.brokers)
      return false;
    return this.brokers.findIndex(b=>b.id==brokerId)>=0;
  }

  selectBroker(brokerId: number, selected: boolean): void {
    if(!this.campaign || !this.brokers)
      return;

    if(selected) {
      if(this.brokers.findIndex(b=>b.id===brokerId)>=0)
        return;
      this.handleCampaignRequest(this.campaignService.patch(this.campaignId, [Patch.add("brokers", brokerId)]));
    }
    else {
      if(this.brokers.findIndex(b=>b.id===brokerId)<0)
        return;
      this.handleCampaignRequest(this.campaignService.patch(this.campaignId, [Patch.remove("brokers", brokerId)]));
    }
  }

  findSources(name: string) {
    this.sourceService.list({
      query: name,
      count: 5
    }).toPromise().then(s=>{
      this.sourceoptions=s.result;
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  /**
   * adds a feed source to a campaign
   * @param sourceId id of source to add to campaign
   */
  addSource(sourceId: number): void {
    if(this.sources && this.sources.findIndex(s=>s.sourceId===sourceId)>=0)
      return;

    this.handleCampaignRequest(this.campaignService.patch(this.campaignId, [Patch.add("sources", sourceId)]));
    this.searchInput.nativeElement.value=""
  }

  /**
   * removes a feed source from a campaign
   * @param sourceId id of source to remove from campaign
   */
  removeSource(sourceId: number): void {
    if(this.sources && this.sources.findIndex(s=>s.sourceId===sourceId)<0)
      return;

    this.handleCampaignRequest(this.campaignService.patch(this.campaignId, [Patch.remove("sources", sourceId)]));
  }
}

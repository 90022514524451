import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeedTransformer } from '../dto/feedtransformers/feedtransformer';
import { FeedTransformerData } from '../dto/feedtransformers/feedtransformerdata';
import { FeedTransformerFilter } from '../dto/feedtransformers/feedtransformerfilter';
import { RestService } from './rest.service';

/**
 * provides management access for {@link FeedTransformer}s
 */
@Injectable({
  providedIn: 'root'
})
export class FeedTransformerService extends RestService<FeedTransformer, FeedTransformer,FeedTransformerData,FeedTransformerFilter> {

  constructor(@Inject('API_URL')apiurl: string, http: HttpClient) {
    super(`${apiurl}/v1/feedtransformer`, http);
  }
}

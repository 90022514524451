import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Campaign } from '../dto/campaigns/campaign';
import { CampaignDetails } from '../dto/campaigns/campaigndetails';
import { CampaignListFilter } from '../dto/campaigns/campaignlistfilter';
import { CreateCampaignRequest } from '../dto/campaigns/createcampaignrequest';
import { RestService } from './rest.service';

/**
 * provides access to campaign data
 */
@Injectable({
  providedIn: 'root'
})
export class CampaignService extends RestService<Campaign, CampaignDetails, CreateCampaignRequest, CampaignListFilter>{

  constructor(@Inject('API_URL')apiurl: string, http: HttpClient) { 
    super(`${apiurl}/v1/campaigns`, http) 
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeoInfo } from '../dto/geo/geoinfo';
import { GeoListFilter } from '../dto/geo/geolistfilter';
import { Page } from '../dto/page';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  private baseUrl: string;

  constructor(@Inject('API_URL')apiurl: string, private http: HttpClient) { 
    this.baseUrl=`${apiurl}/v1/geoinfo`;
  }

  /**
   * finds geo locations by criterias
   * @param filter criterias for search
   */
  find(filter: GeoListFilter) : Observable<Page<GeoInfo>> {
    let params=new HttpParams();
    if(filter) {
      for(let [key,value] of Object.entries(filter)) {
        params=params.append(key, value.toString().toLowerCase());
      }
    }

    return this.http.get<Page<GeoInfo>>(this.baseUrl, {
      params: params
    });
  }

  /**
   * uploads geographic data for import
   * @param data data to upload
   */
  upload(data: Blob) : Observable<any> {
    return this.http.post(`${this.baseUrl}/data`, data);
  }
}

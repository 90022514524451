import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CompanyImageParameters} from "../dto/companies/CompanyImageParameters";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(@Inject('API_URL')private apiUrl: string, private http: HttpClient) {
  }

  /**
   * get image for a company
   * @param companyId id of company of which to get image
   * @param parameters parameters of image to get (optional)
   */
  getCompanyImage(companyId: number, parameters: CompanyImageParameters): Observable<any> {
    let query: any = {};
    if(parameters?.size) query.size = parameters.size;
    if(parameters?.type) query.type = parameters.type;
    return this.http.get(`${this.apiUrl}/v1/companies/${companyId}/image`, {
      params: query,
      responseType: "blob"
    });
  }

  /**
   * get properties of a company
   * @param companyId id of company of which to get properties
   */
  getCompanyProperties(companyId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/companies/${companyId}/properties`);
  }
}

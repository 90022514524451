/**
 * day of week
 */
export enum DayOfWeek {

    /**
     * sunday
     */
    Sunday=0,

    /**
     * monday
     */
    Monday=1,

    /**
     * tuesday
     */
    Tuesday=2,

    /**
     * wednesday
     */
    Wednesday=3,

    /**
     * thursday
     */
    Thursday=4,

    /**
     * friday
     */
    Friday=5,

    /**
     * saturday
     */
    Saturday=6
}

export namespace DayOfWeek {
    export function getName(type: any): string {
        if(typeof type === "number")
            return DayOfWeek[type];

        if(typeof type === "string")
        {
            let num=parseInt(type)
            if(num>=0)
                return DayOfWeek[num];
        }

        return type.toString();
    }

    export function getValue(type: any): DayOfWeek {
        if(type as string) {
            let num=parseInt(type);
            if(num>=0)
                return num;
            return DayOfWeek[type as string];
        }
        return type;
    }
}
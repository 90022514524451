import { Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CampaignService, CreateCampaignRequest } from 'mamgo-api';
import {Errors} from "../../helpers/Errors";

@Component({
  selector: 'app-campaign-creator',
  templateUrl: './campaign-creator.component.html',
  styleUrls: ['./campaign-creator.component.scss']
})
export class CampaignCreatorComponent implements OnInit {
  name: string;
  budget: number;
  cpa: number;

  constructor(private dialog: MatDialogRef<CampaignCreatorComponent>, private campaignService: CampaignService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  createCampaign(): void {
    let request: CreateCampaignRequest={
      name: this.name,
      budget: this.budget,
      cpa: this.cpa
    };

    this.campaignService.create(request)
      .toPromise()
      .then(()=>{
        this.dialog.close();
      })
      .catch(e=>{
        this.snackBar.open(Errors.getError(e));
      });
  }
}

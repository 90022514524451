/**
 * type of click event data transportation
 */
export enum ClickDataType {

  /**
   * event data is retrieved from url
   */
  Url,

  /**
   * event data is provided in body
   */
  Body
}

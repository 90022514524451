import {DocumentService} from "../../../../mamgo-api/src/lib/services/document.service";
import {Errors} from "./Errors";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Injectable} from "@angular/core";

/**
 * service used to download documents
 */
@Injectable({
  providedIn: 'root'
})
export class DocumentDownloadService {

  constructor(private documentService: DocumentService, private snackBar: MatSnackBar) {
  }

  downloadDocument(document: Document, fileName: string, progress?:(p: number)=>void):void {
    this.processResponse(document, fileName, progress);
  }

  private loadDocument(documentId: number, fileName: string, progress:(p: number)=>void): void {
    this.documentService.getDocumentById(documentId)
      .toPromise()
      .then(response=>this.processResponse(response, fileName, progress))
      .catch(error=>{
        this.snackBar.open(Errors.getError(error));
        progress(1.0);
      });
  }

  private processResponse(response: any, fileName: string, progress:(p: number)=>void): void {
    if(response.status && response.status==="Processing") {
      if(progress)
        progress(response.progress);
    }
    else {
      progress(1.0);
      this.downloadBlob(response, fileName);
      return;
    }

    setTimeout(()=>this.loadDocument(response.id, fileName, progress), 1000);
  }

  private downloadBlob(data: Blob, fileName: string): void {
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(data, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(data);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobDetails } from '../dto/jobs/jobdetails';
import { JobFilter } from '../dto/jobs/jobfilter';
import { JobListItem } from '../dto/jobs/joblistitem';
import { JobStatusDetails } from '../dto/jobs/jobstatusdetails';
import { JobStatusLog as JobStatusEntry } from '../dto/jobs/jobstatusentry';
import { JobStatusFilter } from '../dto/jobs/jobstatusfilter';
import { Page } from '../dto/page';

/**
 * access to job data
 */
@Injectable({
  providedIn: 'root'
})
export class JobService {
  private baseUrl: string;

  constructor(@Inject('API_URL')apiurl: string, private http: HttpClient) { 
    this.baseUrl=`${apiurl}/v1`;
  }

  /**
   * lists known jobs of system
   * @param filter filter for jobs to list
   */
  list(filter: JobFilter) : Observable<Page<JobListItem>> {
    let params=new HttpParams();
    if(filter) {
      for(let [key,value] of Object.entries(filter)) {
        params=params.append(key, value.toString().toLowerCase());
      }
    }

    return this.http.get<Page<JobListItem>>(`${this.baseUrl}/jobs`, {
      params: params
    });
  }

  /**
   * get job information by id
   * @param jobid id of job to get
   */
  getById(jobid: number) : Observable<JobDetails> {
    return this.http.get<JobDetails>(`${this.baseUrl}/jobs/${jobid}`);
  }

  /**
   * lists job validation results
   * @param filter filter for jobs to list
   */
  listStatus(filter: JobStatusFilter) : Observable<Page<JobStatusEntry>> {
    let params=new HttpParams();
    if(filter) {
      for(let [key,value] of Object.entries(filter)) {
        params=params.append(key, value.toString().toLowerCase());
      }
    }

    return this.http.get<Page<JobStatusEntry>>(`${this.baseUrl}/jobstatus`, {
      params: params
    });
  }

  /**
   * get validation status log of a job
   * @param jobid id of job
   * @param brokerid id of broker for which job was validated
   */
  getStatus(jobid: number, brokerid: number) : Observable<JobStatusDetails> {
    return this.http.get<JobStatusDetails>(`${this.baseUrl}/jobstatus/${jobid}/${brokerid}`);
  }
}

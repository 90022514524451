<div class="pagecontent">
    <div class="titlecontainer">
        <h1>Analysis</h1>
    </div>
    <div class="container">
        <mat-form-field class="property" appearance="fill">
            <mat-label>From</mat-label>
            <input matInput [(ngModel)]="filter.from" [matDatepicker]="frompicker" (ngModelChange)="loadPerformanceData()">
            <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
            <mat-datepicker #frompicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="property" appearance="fill">
            <mat-label>To</mat-label>
            <input matInput [(ngModel)]="filter.to" [matDatepicker]="topicker" (ngModelChange)="loadPerformanceData()">
            <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
            <mat-datepicker #topicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="property" appearance="fill">
            <mat-label>Metric</mat-label>
            <mat-select [(ngModel)]="filter.metric" (selectionChange)="generateGraph()">
                <mat-option value="Visits">Visits</mat-option>
                <mat-option value="Application Clicks">Application Clicks</mat-option>
                <mat-option value="Applications">Applications</mat-option>
                <mat-option value="Conversion Rate">Conversion Rate</mat-option>
                <mat-option value="Costs">Costs</mat-option>
                <mat-option value="CPAC">Cost per Application Click</mat-option>
                <mat-option value="GeniusFactor">Genius Factor</mat-option>
                <mat-option value="Profit" [disabled]="filter.grouping1!=='Campaign' && filter.grouping2!=='Campaign'">Profit</mat-option>
                <mat-option value="Distance">Distance</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="property" appearance="fill">
            <mat-label>Grouping</mat-label>
            <mat-select [(ngModel)]="filter.grouping1" (selectionChange)="groupingChanged()">
                <mat-option value="Broker">Broker</mat-option>
                <mat-option value="Campaign">Campaign</mat-option>
                <mat-option value="Company">Company</mat-option>
                <mat-option value="Country">Country</mat-option>
                <mat-option value="Job" [disabled]="true">Job</mat-option>
                <mat-option value="Date">Date</mat-option>
                <mat-option value="Cpc">Cpc</mat-option>
                <mat-option value="Hour">Hour of Day</mat-option>
                <mat-option value="DayOfWeek">Day of Week</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="property" appearance="fill">
            <mat-label>Grouping 2</mat-label>
            <mat-select [(ngModel)]="filter.grouping2" [disabled]="filter.grouping2Locked" (selectionChange)="groupingChanged()">
                <mat-option value="None">None</mat-option>
                <mat-option value="Date">Date</mat-option>
                <mat-option value="Cpc">Cpc</mat-option>
                <mat-option value="Broker" [disabled]="filter.grouping1==='Broker'">Broker</mat-option>
                <mat-option value="Campaign" [disabled]="filter.grouping1==='Campaign'">Campaign</mat-option>
                <mat-option value="Company" [disabled]="filter.grouping1==='Company'">Company</mat-option>
                <mat-option value="Country" [disabled]="filter.grouping1==='Country'">Country</mat-option>
                <mat-option value="Hour">Hour of Day</mat-option>
                <mat-option value="DayOfWeek">Day of Week</mat-option>
              <mat-option value="DayOfMonth">Day of Month</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="property" appearance="fill">
            <mat-label>Min Cpc</mat-label>
            <input matInput [(ngModel)]="filter.cpcMin" (ngModelChange)="loadPerformanceData()">
        </mat-form-field>
        <mat-form-field class="property" appearance="fill">
            <mat-label>Max Cpc</mat-label>
            <input matInput [(ngModel)]="filter.cpcMax" (ngModelChange)="loadPerformanceData()">
        </mat-form-field>
        <mat-checkbox [(ngModel)]="filter.useBrokerClicks" (ngModelChange)="generateGraph()">Use Broker Clicks</mat-checkbox>
        <mat-checkbox [(ngModel)]="filter.includeDuplicates" (ngModelChange)="loadPerformanceData()">Include Duplicates</mat-checkbox>
    </div>
    <div class="container">
        <button mat-raised-button color="primary" (click)="popFilter()" [disabled]="filterStack.length===0">Back</button>
        <ngx-charts-line-chart class="graph" *ngIf="filter.grouping2!=='None'"
        [results]="data"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [legend]="true"
        [xAxis]="true"
        [yAxis]="true"
        [xAxisLabel]="filter.grouping2"
        [yAxisLabel]="filter.metric"
        [view]="[1600,400]"
        (select)="itemSelected($event)">
        </ngx-charts-line-chart>
        <ngx-charts-bar-vertical class="graph" *ngIf="filter.grouping2==='None'"
        [results]="stackdata"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [legend]="true"
        [xAxis]="true"
        [yAxis]="true"
        [yAxisLabel]="filter.metric"
        [view]="[1600,400]">
        </ngx-charts-bar-vertical>
    </div>

    <div class="container mat-elevation-z8">
        <ng-container *ngIf="generatingDataTable">
          Data Table is being processed ...
        </ng-container>
        <ng-container *ngIf="!generatingDataTable">
          <button mat-raised-button color="primary" (click)="exportCsv()">CSV Export</button>
          <table mat-table [dataSource]="resulttable" *ngIf="filter.grouping1!=='Job'">
              <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
                  <th mat-header-cell *matHeaderCellDef>{{column.display}}</th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container [ngSwitch]="column">
                      <div *ngSwitchCase="'Rating'">
                        <ng-container *ngFor="let filled of getPerformance(element.Genius)">
                          <img class="icon" [src]="'/assets/star-'+(filled?'filled':'empty')+'.png'" >
                        </ng-container>
                      </div>
                      <span *ngSwitchDefault>{{element[column.name] |display:column.format}}</span>
                    </ng-container>
                  </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="Tables.getColumns(columns)"></tr>
              <tr mat-row [class.summary]="row.type===1" *matRowDef="let row; columns: Tables.getColumns(columns);"></tr>
          </table>
        </ng-container>
    </div>
</div>

import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {PerformanceFilterV2} from "../dto/performance/performancefilterv2";
import {Observable} from "rxjs";
import {Rest} from "../helpers/rest";
import {JobPostingReportFilter} from "../dto/analytics/job-posting-report-filter";

/**
 * provides analytics data
 */
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private baseUrl: string;

  /**
   * creates a new {@link AnalyticsService}
   * @param apiUrl url pointing to backend api
   * @param http http client to use to send requests
   */
  constructor(@Inject('API_URL')apiUrl: string, private http: HttpClient) {
    this.baseUrl=`${apiUrl}/v1/analytics`;
  }

  /**
   * generates a report document for click data
   * @param filter filter specifying which clicks to include and which groupings to apply
   */
  generateReport(filter: PerformanceFilterV2): Observable<Document> {
    return this.http.get<Document>(`${this.baseUrl}/reports`, {
      params: Rest.createParameters(filter)
    });
  }

  /**
   * generates a report document for job posting entries
   * @param filter filter specifying data for which to generate report
   */
  generateJobPostingReport(filter: JobPostingReportFilter): Observable<Document> {
    return this.http.get<Document>(`${this.baseUrl}/reports/jobpool`, {
      params: Rest.createParameters(filter)
    });
  }
}

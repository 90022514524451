<div class="pagecontent">
    <div class="container">
        <h1>Brokers</h1>
    </div>
    <div class="container mat-elevation-z8">
        <table mat-table [dataSource]="brokers" >
            <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
                <th mat-header-cell *matHeaderCellDef>{{column.display}}</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngSwitch]="column.name">
                        <div *ngSwitchCase="'_actions'">
                            <button mat-icon-button (click)="showFeed(element)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </div>
                        <span *ngSwitchDefault>{{Tables.getData(element, column.name) | display:column.format}}</span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="Tables.getColumns(columns)"></tr>
            <tr mat-row *matRowDef="let row; columns: Tables.getColumns(columns);"></tr>
        </table>
    </div>
</div>
/**
 * frequencies of source updates
 */
export enum UpdateFrequency {

        /*
        * source is not updated (thus a static source)
        */
        None=0,
        
        /*
        * once every hour
        */
        Hourly=1,
        
        /*
        * every six hours
        */
        HexaHourly=2,
        
        /*
        * every 12 hours
        */
        HalfDaily=3,
        
        /*
        * source is updated every other day
        */
        Daily=4,
        
        /*
        * source is updated every other week
        */
        Weekly=5,
        
        /*
        * source is updated every other month
        */
        Monthly=6

}
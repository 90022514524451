/**
 * type of text content
 */
export enum ContentType {

    /**
     * default, raw text
     */
    Text=0,

    /**
     * html format
     */
    Html=1
}
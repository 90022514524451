import {Component, OnInit} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Broker, BrokerService, PerformanceItemGroup, PerformanceService, PerformanceEntry, CampaignService, Campaign, DayOfWeek, TableService } from 'mamgo-api';
import {FilterSettings} from "./filtersettings";
import {Column} from "../../models/column";
import {Tables} from "../../helpers/tables";

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {
  Tables=Tables;
  filterStack: FilterSettings[]=[];
  filter: FilterSettings={
    from: new Date(Date.now()-604800000), // set default to a week ago
    useBrokerClicks: false,
    metric: 'Visits',
    grouping1: 'Broker',
    grouping2: 'Date',
    grouping2Locked: false,
    includeDuplicates: false
  };

  brokers: Map<number, Broker>=new Map<number, Broker>();
  campaigns: Map<number, Campaign>=new Map<number, Campaign>();

  entries:PerformanceEntry[]=[];
  data = [];
  stackdata=[];

  generatingDataTable: boolean=false;
  resulttable: MatTableDataSource<any>=new MatTableDataSource<any>();
  columns: Column[]=[];

  constructor(private brokerService: BrokerService,
              private performanceService: PerformanceService,
              private campaignService: CampaignService,
              private snackBar: MatSnackBar,
              private tableService: TableService) { }

  ngOnInit(): void {
    this.loadBrokers();
    this.loadCampaigns();
    this.loadPerformanceData();
  }

  groupingChanged(): void {
    if (this.filter.metric === "Profit" && this.filter.grouping1 !== "Campaign" && this.filter.grouping2 !== "Campaign")
      this.filter.metric = "Visits";

    this.filter.campaignId = null;
    this.filter.brokerId = null;
    if (this.filter.grouping2 === this.filter.grouping1)
      this.filter.grouping2 = "None";

    switch (this.filter.grouping1) {
      case "Date":
      case "Cpc":
      case "Hour":
      case "DayOfWeek":
        // reset secondary grouping to none
        // since secondary grouping makes not a lot of sense when primary is date or cpc
        this.filter.grouping2 = "None";
        this.filter.grouping2Locked = true;
        break;
      default:
        this.filter.grouping2Locked = false;
        break;
    }
    this.loadPerformanceData();
  }

  addGroupingParameter(grouping: string, parameter: PerformanceItemGroup[]) : void {
    switch(grouping) {
      case "Broker":
        parameter.push(PerformanceItemGroup.Broker);
        break;
      case "Campaign":
        parameter.push(PerformanceItemGroup.Campaign);
        break;
      case "Company":
        parameter.push(PerformanceItemGroup.Company);
        break;
      case "Country":
        parameter.push(PerformanceItemGroup.Country);
        break;
      case "Job":
        parameter.push(PerformanceItemGroup.Job);
        break;
      case "Date":
        parameter.push(PerformanceItemGroup.Date);
        break;
      case "Cpc":
        parameter.push(PerformanceItemGroup.Cpc);
        break;
      case "Hour":
        parameter.push(PerformanceItemGroup.Hour);
        break;
      case "DayOfWeek":
        parameter.push(PerformanceItemGroup.DayOfWeek);
        break;
      case "DayOfMonth":
        parameter.push(PerformanceItemGroup.DayOfMonth);
        break;
    }
  }

  pushFilter(): void {
    this.filterStack.push(Object.assign({}, this.filter))
  }

  popFilter(): void {
    if(this.filterStack.length===0)
      return;

    this.filter=this.filterStack[this.filterStack.length-1];
    this.filterStack=this.filterStack.slice(this.filterStack.length-1);
    this.loadPerformanceData();
  }

  itemSelected(event): void {
    if(typeof event === 'string' || event instanceof String) {
      switch(this.filter.grouping1) {
        case "Campaign":
          for(let key of this.campaigns.values()) {
            if(key.name===event)
            {
              this.pushFilter();
              this.filter.campaignId=key.id;
              this.filter.grouping1="Job";
              break;
            }
          }
          break;
        case "Broker":
          for(let key of this.brokers.values()) {
            if(key.name===event) {
              this.pushFilter();
              this.filter.brokerId=key.id;
              this.filter.grouping1="Job";
              break;
            }
          }
          break;
      }
      this.loadPerformanceData();
    }
  }

  loadPerformanceData(): void {
    let group: PerformanceItemGroup[]=[];
    this.addGroupingParameter(this.filter.grouping1, group);
    this.addGroupingParameter(this.filter.grouping2, group);

    let to;
    if(this.filter.to) {
      to=new Date(this.filter.to.getTime());
      to.setDate(to.getDate()+1);
    }

    this.performanceService.clicks({
      from: this.filter.from,
      to: to,
      group: group,
      campaignId: this.filter.campaignId?[this.filter.campaignId]:null,
      brokerId: this.filter.brokerId?[this.filter.brokerId]:null,
      cpcMin: this.filter.cpcMin,
      cpcMax: this.filter.cpcMax,
      detail: [
        'job.title',
        'campaign.name',
        'campaign.ccr',
        'campaign.cpa',
        'broker.name'],
      includeDuplicates: this.filter.includeDuplicates
    }).toPromise().then(result=>{
      this.entries=result;
      this.generateGraph();
    }).catch(e=>{
      if(e.error)
        this.snackBar.open(e.error.text);
      else {
        console.log(e);
      }
    });
  }

  private sortEntries(): void {
    switch(this.filter.grouping2) {
      case "Date":
        this.entries=this.entries.sort((lhs,rhs)=>{
          return lhs.time.toString().localeCompare(rhs.time.toString());
        });
        break;
      case "Cpc":
        this.entries=this.entries.sort((lhs,rhs)=>{
          return lhs.cpc>rhs.cpc?1:-1;
        });
        break;
      case "Hour":
        this.entries=this.entries.sort((lhs,rhs)=>{
          return lhs.hour>rhs.hour?1:-1;
        });
        break;
      case "DayOfWeek":
        this.entries=this.entries.sort((lhs,rhs)=>{
          return DayOfWeek.getValue(lhs.dayOfWeek)>DayOfWeek.getValue(rhs.dayOfWeek)?1:-1;
        });
        break;
      case "DayOfMonth":
        this.entries=this.entries.sort((lhs,rhs)=>{
          return lhs.dayOfMonth>rhs.dayOfMonth?1:-1;
        });
        break;
      default:
        switch(this.filter.grouping1) {
          case "Date":
            this.entries=this.entries.sort((lhs,rhs)=>{
              return lhs.time.toString().localeCompare(rhs.time.toString());
            });
            break;
          case "Cpc":
            this.entries=this.entries.sort((lhs,rhs)=>{
              return lhs.cpc>rhs.cpc?1:-1;
            });
            break;
          case "Hour":
            this.entries=this.entries.sort((lhs,rhs)=>{
              return lhs.hour>rhs.hour?1:-1;
            });
            break;
          case "DayOfWeek":
            this.entries=this.entries.sort((lhs,rhs)=>{
              return DayOfWeek.getValue(lhs.dayOfWeek)>DayOfWeek.getValue(rhs.dayOfWeek)?1:-1;
            });
            break;
          default:
            switch(this.filter.metric) {
              case 'Visits':
                this.entries = this.entries.sort((lhs, rhs) => {
                  return lhs.visits - rhs.visits;
                });
                break;
              case 'Application Clicks':
                this.entries = this.entries.sort((lhs, rhs) => {
                  return lhs.applicationClicks - rhs.applicationClicks;
                });
                break;
              case 'Conversion Rate':
                this.entries = this.entries.sort((lhs, rhs) => {
                  return (lhs.applicationClicks / lhs.visits) - (rhs.applicationClicks / rhs.visits);
                });
                break;
              case 'Costs':
                this.entries = this.entries.sort((lhs, rhs) => {
                  return (lhs.costs) - (rhs.costs);
                });
                break;
              case 'CPAC':
                this.entries = this.entries.sort((lhs, rhs) => {
                  return (lhs.costs / lhs.applicationClicks) - (rhs.costs / rhs.applicationClicks);
                });
                break;
              case 'GeniusFactor':
                this.entries = this.entries.sort((lhs, rhs) => {
                  return lhs.excellence - rhs.excellence;
                });
                break;
            }
            break;
        }
        break;
    }
  }

  private loadBrokers(): void {
    this.brokerService.listBrokers().toPromise().then(b=>{
      for(let broker of b)
        this.brokers.set(broker.id, broker);
      if(this.filter.grouping1==="Broker" || this.filter.grouping2=="Broker")
        this.generateGraph();
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  private loadCampaigns(): void {
    this.campaignService.list().toPromise().then(c=>{
      for(let campaign of c.result)
        this.campaigns.set(campaign.id, campaign);
      if(this.filter.grouping1==="Campaign" || this.filter.grouping2=="Campaign")
        this.generateGraph();
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  getBrokerName(brokerId: number): string {
    if(this.brokers.has(brokerId))
      return this.brokers.get(brokerId).name;
    return "Broker "+brokerId;
  }

  getCampaignName(campaignId: number): string {
    if(this.campaigns.has(campaignId))
      return this.campaigns.get(campaignId).name;
    return "Campaign"+campaignId;
  }

  generateGraph() : void {
    this.sortEntries();
    let performancedata=[];

    let labels=[];
    let sortby: string;

    switch(this.filter.grouping1) {
      case "Date":
      case "Cpc":
      case "Hour":
      case "DayOfWeek":
        sortby=this.filter.grouping1;
        break;
      default:
        switch(this.filter.grouping2) {
          case "Date":
          case "Cpc":
          case "Campaign":
          case "Broker":
          case "Hour":
          case "DayOfWeek":
          case "DayOfMonth":
              sortby=this.filter.grouping2;
              break;
        }
    }

    if(sortby) {
      switch(sortby) {
        case "Date":
          let labelmap:Map<string,any>=new Map<string,any>();
          for(let entry of this.entries) {
            let key=entry.time.toString().split('T')[0];
            if(!labelmap.has(key))
              labelmap.set(key, null);
          }
          labels=Array.from(labelmap.keys());
          labels=labels.sort();
          break;
        case "Cpc":
          for(let entry of this.entries) {
            if(labels.indexOf(entry.cpc)<0)
              labels.push(entry.cpc);
          }
          labels=labels.sort();
          break;
        case "Campaign":
          for(let entry of this.entries) {
            let campaign=this.getCampaignName(entry.campaignId);
            if(labels.indexOf(campaign)<0)
              labels.push(campaign);
          }
          labels=labels.sort();
          break;
        case "Broker":
          for(let entry of this.entries) {
            let broker=this.getBrokerName(entry.brokerId);
            if(labels.indexOf(broker)<0)
              labels.push(broker);
          }
          labels=labels.sort();
          break;
        case "Hour":
          for(let i=0;i<24;++i)
            labels.push(i.toString());
          break;
        case "DayOfWeek":
          labels.push("Monday");
          labels.push("Tuesday");
          labels.push("Wednesday");
          labels.push("Thursday");
          labels.push("Friday");
          labels.push("Saturday");
          labels.push("Sunday");
          break;
        case "DayOfMonth":
          for(let i=0;i<31;++i)
            labels.push(i.toString());
          break;
      }
    }

    for(let entry of this.entries) {
      let group: string;

      switch(this.filter.grouping1) {
        case "Broker":
          group = this.getBrokerName(entry.brokerId);
          break;
        case "Campaign":
          group = this.getCampaignName(entry.campaignId);
          break;
        case "Company":
          group = entry.companyId.toString();
          break;
        case "Country":
          group = entry.country;
          break;
        case "Date":
          group = entry.time.toString().split('T')[0];
          break;
        case "Cpc":
          group = entry.cpc.toString();
          break;
        case "Hour":
          group = entry.hour.toString();
          break;
        case "DayOfWeek":
          group = DayOfWeek.getName(entry.dayOfWeek);
          break;
        case "Job":
          group = `${entry.jobTitle} (${entry.jobId})`;
          break;
      }
      if(!group)
        group='';

      let value=0;
      switch(this.filter.metric) {
        case "Visits":
          if (this.filter.useBrokerClicks && entry.brokerClicks > 0)
            value = entry.brokerClicks;
          else value = entry.visits;
          break;
        case "Application Clicks":
          value = entry.applicationClicks;
          break;
        case "Applications":
          value = entry.applications;
          break;
        case "Conversion Rate":
          if (entry.visits === 0)
            value = 0;
          else value = entry.applicationClicks / entry.visits * 100.0;
          break;
        case "Costs":
          value = entry.costs;
          break;
        case "CPAC":
          if (entry.applicationClicks === 0)
            value = 0;
          else value = entry.costs / entry.applicationClicks;
          break;
        case "Profit":
          if (entry.campaignId && this.campaigns.has(entry.campaignId)) {
            const campaign = this.campaigns.get(entry.campaignId);
            if (campaign.cpa > 0.0) {
              if (campaign.ccr > 0.0)
                value = entry.applicationClicks * campaign.ccr * campaign.cpa - entry.costs;
              else value = entry.applications * campaign.cpa - entry.costs;
            } else value = 0;
          } else value = 0;
          break;
        case "GeniusFactor":
          value = entry.excellence;
          break;
        case "Distance":
          if (entry.distance)
            value = entry.distance;
          else value = 0.0;
          break;
      }

      if(this.filter.grouping2==="None") {
          performancedata.push({
            name: group,
            value: value
          });
      }
      else {
        let item=performancedata.find(d=>d.name===group);
        if(!item) {
          let series = [];
          for (let value of labels) {
            series.push({
              name: value,
              value: 0
            });
          }
          item = {
            name: group,
            series: series
          };
          performancedata.push(item);
        }

        let seriesvalue;
        switch(this.filter.grouping2) {
          case "Date":
            seriesvalue = item.series.find(i => i.name === entry.time.toString().split('T')[0]);
            break;
          case "Cpc":
            seriesvalue = item.series.find(i => i.name === entry.cpc);
            break;
          case "Broker":
            let broker = this.getBrokerName(entry.brokerId);
            seriesvalue = item.series.find(i => i.name == broker);
            break;
          case "Campaign":
            let campaign = this.getCampaignName(entry.campaignId);
            seriesvalue = item.series.find(i => i.name == campaign);
            break;
          case "Company":
            seriesvalue = item.series.find(i => i.company == entry.companyId);
            break;
          case "Country":
            seriesvalue = item.series.find(i => i.country == entry.country);
            break;
          case "Hour":
            seriesvalue = item.series.find(i => i.name == entry.hour);
            break;
          case "DayOfWeek":
            seriesvalue = item.series.find(i => i.name == DayOfWeek.getName(entry.dayOfWeek));
            break;
          case "DayOfMonth":
            seriesvalue = item.series.find(i => i.name == entry.dayOfMonth);
            break;
        }
        if (seriesvalue)
          seriesvalue.value = value;
      }
    }

    if(this.filter.grouping2==="None")
      this.stackdata=performancedata;
    else this.data=performancedata;

    this.generateDataTable();
  }

  private async generateDataTable(): Promise<any> {
    this.generatingDataTable = true;
    let columns: Column[] = [];
    let data: any[] = [];

    columns.push({name: this.filter.grouping1, display: this.filter.grouping1});
    if (this.filter.grouping2)
      columns.push({name: this.filter.grouping2, display: this.filter.grouping2});
    columns.push({name: "BC", display: "BC"});
    columns.push({name: "Visits", display: "Visits"});
    columns.push({name: "AppClicks", display: "AppClicks"});
    columns.push({name: "Applications", display: "Applications"});
    columns.push({name: "Bot", display: "Bot"});
    columns.push({name: "ACC", display: "ACC", format: "Percint"});
    columns.push({name: "CCR", display: "CCR", format: "Percint"});
    columns.push({name: "Costs", display: "Costs", format: "Currency"});
    columns.push({name: "CPAC", display: "CPAC", format: "Currency"});
    columns.push({name: "CPA", display: "CPA", format: "Currency"});
    columns.push({name: "Genius", display: "Genius", format: "4 Digits"});
    columns.push({name: "Profit", display: "Profit", format: "Currency"});
    columns.push({name: "Rating", display: "Rating"});
    columns.push({name: "Distance", display: "Distance", format: "Distance"});

    let extractor = this.generateExtractor(this.filter.grouping1);
    let secondaryextractor = this.generateExtractor(this.filter.grouping2);

    for (let entry of this.entries) {
      let item: any = {};
      item.type = 0;
      item[this.filter.grouping1] = extractor(entry);
      if (secondaryextractor)
        item[this.filter.grouping2] = secondaryextractor(entry);

      item["BC"] = entry.brokerClicks;
      item["Visits"] = entry.visits;
      item["AppClicks"] = entry.applicationClicks;
      item["Applications"] = entry.applications;
      item.Bot = entry.bot;
      if (entry.visits > 0)
        item["ACC"] = entry.applicationClicks / entry.visits;
      if (entry.applications > 0)
        item["CCR"] = entry.applications / Math.max(entry.applications, entry.applicationClicks);
      item["Costs"] = entry.costs;
      item.Distance = entry.distance;

      if (entry.applicationClicks > 0)
        item.CPAC = entry.costs / entry.applicationClicks;
      if (entry.applications > 0)
        item.CPA = entry.costs / entry.applications;

      item.Genius = entry.excellence;
      if (entry.profit)
        item.Profit = entry.profit;
      data.push(item);
    }

    let summary: any = {};
    summary.type = 1;
    summary.BC = this.entries.reduce((sum, entry) => sum + entry.brokerClicks, 0);
    summary.Visits = this.entries.reduce((sum, entry) => sum + entry.visits, 0);
    summary.AppClicks = this.entries.reduce((sum, entry) => sum + entry.applicationClicks, 0);
    summary.Applications = this.entries.reduce((sum, entry) => sum + entry.applications, 0);
    summary.Bot = this.entries.reduce((sum, entry) => sum + entry.bot, 0);
    summary.TimeSum = this.entries.reduce((sum, entry) => sum + entry.timePosted, 0);
    if (summary.Visits > 0)
      summary.ACC = summary.AppClicks / summary.Visits;
    if (summary.Applications > 0)
      summary.CCR = summary.Applications / Math.max(summary.Applications, summary.AppClicks);
    summary.Costs = this.entries.reduce((sum, entry) => sum + entry.costs, 0);
    if (summary.AppClicks > 0)
      summary.CPAC = summary.CostSum / summary.AppClicks;
    if (summary.Applications > 0)
      summary.CPA = summary.CostSum / summary.Applications;

    summary.Genius = this.entries.reduce((sum, entry) => sum + (entry.excellence | 0) / this.entries.length, 0);
    summary.Profit = this.entries.reduce((sum, entry) => sum + (entry.profit | 0), 0);
    data.push(summary);

    this.columns = columns;
    this.resulttable.data = data;
    this.generatingDataTable = false;
    //this.changeDetector.markForCheck();
  }

  getPerformance(excellence: number): any[] {
    if (excellence < 0.5)
      return [false, false, false, false, false];
    if (excellence < 1)
      return [true, false, false, false, false];
    if (excellence < 2)
      return [true, true, false, false, false];
    if (excellence < 4)
      return [true, true, true, false, false];
    if (excellence < 8)
      return [true, true, true, true, false];
    return [true, true, true, true, true];
  }

  private generateExtractor(column: string): any {
    switch (column) {
      case "Date":
        return (entry: PerformanceEntry) => entry.time.toString().split('T')[0];
      case "Cpc":
        return (entry: PerformanceEntry) => entry.cpc;
      case "Campaign":
        return (entry: PerformanceEntry) => this.getCampaignName(entry.campaignId);
      case "Company":
        return (entry: PerformanceEntry) => entry.companyId;
      case "Country":
        return (entry: PerformanceEntry) => entry.country;
      case "Broker":
        return (entry: PerformanceEntry) => this.getBrokerName(entry.brokerId);
      case "Hour":
        return (entry: PerformanceEntry) => entry.hour;
      case "DayOfWeek":
        return (entry: PerformanceEntry) => DayOfWeek.getName(entry.dayOfWeek);
      case "DayOfMonth":
        return (entry: PerformanceEntry) => entry.dayOfMonth;
      case "Job":
        return (entry: PerformanceEntry) => `${entry.jobTitle} (${entry.jobId})`;
      default:
        return undefined;
    }
  }

  exportCsv(): void {
    this.tableService.exportToCsv("Mamgo-Analysis.csv", this.resulttable.data);
  }
}

<div class="pagecontent">
  <div class="titlecontainer">
    <h1>Reports</h1>
  </div>
  <div class="container mat-elevation-z8">
    <mat-form-field class="property" appearance="fill">
      <mat-label>Report Type</mat-label>
      <mat-select [(ngModel)]="reportType">
        <mat-option value="Clicks">Clicks</mat-option>
        <mat-option value="Postings">Job Postings</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="container mat-elevation-z8">
    <h3>Data Range</h3>
    <mat-form-field class="property" appearance="fill">
      <mat-label>From</mat-label>
      <input matInput [(ngModel)]="filter.from" [matDatepicker]="frompicker">
      <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
      <mat-datepicker #frompicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="property" appearance="fill">
      <mat-label>To</mat-label>
      <input matInput [(ngModel)]="filter.to" [matDatepicker]="topicker">
      <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
      <mat-datepicker #topicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="property" appearance="fill">
      <mat-label>Min Cpc</mat-label>
      <input matInput [(ngModel)]="filter.cpcMin" [disabled]="reportType==='Postings'">
    </mat-form-field>
    <mat-form-field class="property" appearance="fill">
      <mat-label>Max Cpc</mat-label>
      <input matInput [(ngModel)]="filter.cpcMax" [disabled]="reportType==='Postings'">
    </mat-form-field>
  </div>
  <div class="container mat-elevation-z8">
    <h3 matTooltip="Group result Data by these aspects\nBe careful when including groups with a lot if details as it will increase the result table size a lot.\nFor example job + campaign + cpc + date will generate a lot of result rows">Grouping</h3>
    <ng-container *ngFor="let grouping of groups">
      <mat-checkbox class="reportfilter" [checked]="hasGrouping(grouping.group)" (change)="toggleGrouping(grouping.group)" [disabled]="reportType==='Postings'">{{grouping.display}}</mat-checkbox>
    </ng-container>
  </div>
  <div class="container mat-elevation-z8">
    <h3 matTooltip="Brokers to include in report">Brokers</h3>
    <ng-container *ngFor="let broker of brokers">
      <mat-checkbox class="reportfilter" [checked]="hasBroker(broker.id)" (change)="toggleBroker(broker.id)">{{broker.name}}</mat-checkbox>
    </ng-container>
  </div>
  <div class="container mat-elevation-z8">
    <h3 matTooltip="Campaigns to include in report">Campaigns</h3>
    <mat-checkbox class="reportfilter" *ngFor="let campaign of campaigns" [checked]="hasCampaign(campaign.id)" (change)="toggleCampaign(campaign.id)">{{campaign.name}}</mat-checkbox>
  </div>
  <div class="container mat-elevation-z8">
    <h3 matTooltip="Details to include in extracted data\nBe careful when including large details like job description in combination with groupings. This will most likely result in a timeout and if something is returned, the filesize will be tremendeous.">Details</h3>
    <mat-checkbox class="reportfilter" *ngFor="let detail of details" [disabled]="!detail.active || reportType==='Postings'" [checked]="hasDetail(detail.name)" (change)="toggleDetail(detail.name)">{{detail.display}}</mat-checkbox>
  </div>
  <div class="container mat-elevation-z8">
    <mat-form-field class="property" appearance="fill">
      <mat-label>Format</mat-label>
      <mat-select [(ngModel)]="filter.format">
        <mat-option [value]="ReportFormat.Json">Json</mat-option>
        <mat-option [value]="ReportFormat.Csv">Csv</mat-option>
        <mat-option [value]="ReportFormat.Excel">Excel</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="generateReport()" [disabled]="generating">Generate</button>
    <div  class="container" *ngIf="generating">
      <span>Generating Report</span>
      <div class="progressbox">
        <div class="progressbar" [style.width.%]="progress*100"></div>
      </div>
    </div>
  </div>
</div>

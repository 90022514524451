<h2>Create Campaign</h2>
<mat-form-field appearance="fill" class="input-full-width">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="name">
</mat-form-field>
<mat-form-field appearance="fill" class="input-full-width">
    <mat-label>Budget</mat-label>
    <input matInput [(ngModel)]="budget">
</mat-form-field>
<mat-form-field appearance="fill" class="input-full-width">
  <mat-label>Cpa</mat-label>
  <input matInput [(ngModel)]="cpa">
</mat-form-field>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="createCampaign()">Create</button>
</mat-dialog-actions>

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DatabaseService } from 'projects/mamgo-api/src/public-api';
import { Column } from '../../models/column';
import {Errors} from "../../helpers/Errors";

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnInit {

  statement: string="";

  resulttable: MatTableDataSource<any>=new MatTableDataSource<any>();
  columns: string[];

  constructor(private databaseService: DatabaseService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  sendStatement(): void {
    this.databaseService.query(this.statement)
    .toPromise().then(r=>{
      this.columns=r.columns;
      let result=[];
      for(let row of r.rows) {
        let item={};
        let index=0;
        for(let column of this.columns)
          item[column]=row[index++];
        result.push(item);
      }
      this.resulttable.data=result;
    }).catch(e=>{
      this.resulttable.data=null;
      this.columns=null;
      this.snackBar.open(Errors.getError(e));
    });
  }
}

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CampaignDetails, CampaignStatus, CampaignService, DocumentService, ExcellenceService, PerformanceItemType, ExcellenceEntry, TableService} from "mamgo-api";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Errors} from "../../helpers/Errors";
import {Column} from "../../models/column";
import {Tables} from "../../helpers/tables";
import { Paging } from '../../helpers/paging';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-campaign-viewer',
  templateUrl: './campaign-viewer.component.html',
  styleUrls: ['./campaign-viewer.component.scss']
})
export class CampaignViewerComponent implements OnInit {
  Tables=Tables;
  Paging=Paging;

  campaign: CampaignDetails={
    id: 0,
    brokers: [],
    sources: [],
    items: [],
    name: "",
    description: "",
    budget: 0,
    jobSelection: 0,
    status: CampaignStatus.Created,
    budgetFactor: 0,
    predicate: "",
    cpa: 0,
    ccr: 0,
    useMamgoLandingPages: false,
    useJobUfo: false
  };

  excellence: ExcellenceEntry[];
  pageData: MatTableDataSource<ExcellenceEntry>=new MatTableDataSource<ExcellenceEntry>();
  page: number=0;
  pageSize: number=25;

  isgenerating: boolean=true;
  progress: number=0.0;
  timer: Subscription;

  columns: Column[]=[{
    name: "broker",
    display: "Broker"
  }, {
    name: "jobTitle",
    display: "Job"
  }, {
    name: "prognosis",
    display: "Prognosis",
    format: "4 Digits"
  }, {
    name: "measured",
    display: "Measured",
    format: "4 Digits"
  }, {
    name: "progress",
    display: "Progress",
    format: "Percent"
  }, {
    name: "excellence",
    display: "Excellence",
    format: "4 Digits"
  }, {
    name: "performance",
    display: "Performance"
  }, {
    name: "temperatureLabel",
    display: "Temperature"
  }, {
    name: "exhaustion",
    display: "Exhaustion",
    format: "Integer"
  }, {
    name: "action",
    display: "Action",
    format: "2 Digits"
  }, {
    name: "profit",
    display: "Profit"
  }, {
    name: "targetStatus",
    display: "Status"
  }];

  constructor(route: ActivatedRoute,
              private campaignService: CampaignService,
              private excellenceService: ExcellenceService,
              private snackBar: MatSnackBar,
              private tableService: TableService,
              private documentService: DocumentService,
              private changeDetector: ChangeDetectorRef) {
    this.campaign.id=route.snapshot.params.campaignId;
  }

  ngOnInit(): void {
    this.campaignService.getById(this.campaign.id, {
      itemStatus: [],
      withoutDetails: true
    }).toPromise()
      .then(campaign=>this.campaign=campaign)
      .catch(error=>{
        this.snackBar.open(Errors.getError(error));
      });

    this.excellenceService.determineExcellence({
      campaignId: [this.campaign.id],
      resultType: PerformanceItemType.Target
    }).toPromise()
      .then(response=>{
        this.processResponseDocument(response);
      })
      .catch(error=>{
        this.snackBar.open(Errors.getError(error));
      });
  }

  processResponseDocument(response: any): void {
    if(response.status && response.status==="Processing") {
      this.progress = response.progress;
      this.changeDetector.markForCheck();
    }
    else {
      this.excellence = <ExcellenceEntry[]>response;
      this.showPage(0);
      this.isgenerating=false;
      return;
    }

    setTimeout(()=>this.loadDocumentResponse(response.id), 1000);
  }

  showPage(page: number): void {
    const startOffset=page*this.pageSize;
    this.pageData.data=this.excellence.slice(startOffset, startOffset+this.pageSize);
  }

  onPageChanged(pageIndex: number, pageSize: number): void {
    this.page=pageIndex;
    this.pageSize=pageSize;
    this.showPage(this.page);
  }

  loadDocumentResponse(documentId: number) {
    this.documentService.getDocumentById(documentId)
      .toPromise()
      .then(response=>this.processResponseDocument(response))
      .catch(error=>{
        this.snackBar.open(Errors.getError(error));
      });
  }

  getPerformance(excellence: number): any[] {
    if (excellence < 0.5)
      return [false, false, false, false, false];
    if (excellence < 1)
      return [true, false, false, false, false];
    if (excellence < 2)
      return [true, true, false, false, false];
    if (excellence < 4)
      return [true, true, true, false, false];
    if (excellence < 8)
      return [true, true, true, true, false];
    return [true, true, true, true, true];
  }

  getTemperature(temperature: string): any[]{
    switch(temperature) {
      case 'Freezing':
        return [false, false, false];
      case 'Cold':
        return [false, false];
      case 'Chilly':
        return [false];
      case 'Warm':
        return [true];
      case 'Hot':
        return [true, true];
      case 'Burning':
        return [true, true, true];
    }

    return [];
  }

  getProfit(profit: number): boolean[] {
    if(profit>25)
      return [true, true, true];
    if(profit>12)
      return [true, true];
    if(profit>5)
      return [true];
    if(profit<-15)
      return [false, false, false];
    if(profit<-7)
      return [false, false];
    if(profit<-3)
      return [false];
    return [];
  }

  exportCsv(): void {
    this.tableService.exportToCsv(`Mamgo-Excellence_${this.campaign.name}.csv`, this.excellence);
  }

  generatePerformanceTooltip(entry: ExcellenceEntry): string {
    return `Visits: ${entry.visits}\nAppClicks: ${entry.appClicks}\nApplications: ${entry.applications}\nCosts: ${entry.costs}`;
  }
}

/**
 * supported formats for {@link FeedSource}s
 */
export enum FeedFormat {
  
    /**
     * eXtendable Markup Language
     */
    Xml=0,

    /**
     * JavaScript Object Notation
     */
    Json=1,

    /**
     * html page
     */
    Html = 2
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ListFilter } from 'mamgo-api';
import { ConfigurationEntry, ConfigurationService } from 'projects/mamgo-api/src/public-api';
import { Paging } from '../../helpers/paging';
import { Tables } from '../../helpers/tables';
import { Column } from '../../models/column';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  Paging=Paging;
  Tables=Tables;

  page: number=0;
  total: number=0;
  filter: ListFilter={
    count: 25,
    continue: 0
  };

  configuration: MatTableDataSource<ConfigurationEntry>=new MatTableDataSource<ConfigurationEntry>();

  columns: Column[]=[{
    name: "key",
    display: "Key"
  }, {
    name: "value",
    display: "Value",
  }, {
    name: "_actions",
    display: ""
  }];

  constructor(private configurationService: ConfigurationService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.loadConfiguration();
  }

  loadConfiguration(): void {
    this.configurationService.listPage(this.filter).toPromise().then(p=>{
      this.total=p.total;
      this.page=this.filter.continue/this.filter.count;
      this.configuration.data=p.result;
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  deleteEntry(key: string): void {
    this.configurationService.removeEntry(key).toPromise().then(()=>{
      this.loadConfiguration();
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  updateConfiguration(entry: ConfigurationEntry): void {
    this.configurationService.setValue(entry.key, entry.value).toPromise().catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  onPageChanged(page: number, size: number): void {
    this.filter.continue=page*size;
    this.filter.count=size;
    this.loadConfiguration();
  }
}

import { Component } from '@angular/core';
import { LoginService } from 'mamgo-api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'management-portal';
  isOpen: boolean=false;

  constructor(private loginservice: LoginService) {
  }

  /**
   * determines whether there is a valid login
   */
  isLoggedIn(): boolean {
    return this.loginservice.getUser()!=null;
  }
}

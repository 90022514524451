import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { JobDetails, JobService } from 'mamgo-api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  jobId: number;
  job: JobDetails;

  constructor(private jobService: JobService, route: ActivatedRoute, private snackBar: MatSnackBar, private router: Router) { 
    this.jobId=route.snapshot.params.jobId;
  }

  ngOnInit(): void {
    this.loadJob();
  }

  private handleJobRequest(request: Observable<JobDetails>): void {
    request.toPromise().then(j=>{
      this.job=j;
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  loadJob(): void {
    this.handleJobRequest(this.jobService.getById(this.jobId));
  }

  showJobLog(jobId: number, brokerId: number): void {
    this.router.navigateByUrl(`/jobs/${jobId}/status/${brokerId}`);
  }
}

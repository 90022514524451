import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultSet } from '../dto/database/resultset';

/**
 * provides an interface to execute database queries
 */
@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private baseUrl: string;

  constructor(@Inject('API_URL')apiurl: string, private http: HttpClient) { 
    this.baseUrl=`${apiurl}/v1/database`;
  }

  /**
   * sends a query to the database and returns result set
   * @param query query to execute
   */
  query(query: string): Observable<ResultSet> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<ResultSet>(this.baseUrl, JSON.stringify(query), {
      headers: headers
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {CampaignKpiService, CampaignWarningService} from "mamgo-api";
import {Paging} from "../../helpers/paging";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Errors} from "../../helpers/Errors";
import {Tables} from "../../helpers/tables";
import {CampaignKpiData} from "./campaign-kpi-data";
import {CampaignWarningData} from "./campaign-warning-data";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  Paging=Paging;
  Tables=Tables;

  campaigns: CampaignKpiData=new CampaignKpiData();
  warnings: CampaignWarningData=new CampaignWarningData();

  constructor(private campaignKpiService: CampaignKpiService, private campaignWarningService: CampaignWarningService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.loadKpis();
    this.campaigns.filterData.month=new Date();
  }

  loadKpis(): void {
    this.campaignKpiService.computeCampaignKpis(this.campaigns.filterData).toPromise()
      .then(page=>{
        this.campaigns.setResultPage(page);
        this.loadWarnings();
      })
      .catch(error=>{
        this.snackBar.open(Errors.getError(error));
      });
  }

  loadWarnings(): void {
    this.campaignWarningService.list({
      campaignId: this.campaigns.items.map((kpi)=>kpi.campaignId),
    }).toPromise()
      .then(page=>{
        this.warnings.setResultData(page);
      })
      .catch(error=>{
        this.snackBar.open(Errors.getError(error));
      });
  }

  onPageChanged(pageIndex: number, pageSize: number): void {
    this.campaigns.setPage(pageIndex, pageSize);
    this.loadKpis();
  }

  getKpiMonths(): Date[] {
    const now=new Date();
    let options: Date[]=[];
    let current=new Date(now.getFullYear(), now.getMonth());
    options.push(current);
    for(let i=0;i<11;++i) {
      current=new Date(current.getFullYear(), current.getMonth()-1);
      options.push(current);
    }
    return options;
  }

  onMonthChange(month: string) {
    this.campaigns.filterData.month=new Date(month);
    this.loadKpis();
  }
}

/*
 * Public API Surface of mamgo-api
 */

export * from './lib/dto/listfilter';
export * from './lib/dto/page';
export * from './lib/dto/patchoperation';
export * from './lib/dto/auth/authtoken';
export * from './lib/dto/auth/user';
export * from './lib/dto/brokers/broker';
export * from './lib/dto/campaigns/campaign';
export * from './lib/dto/campaigns/campaigndetails';
export * from './lib/dto/campaigns/campaignItemFilter';
export * from './lib/dto/campaigns/campaignitemtarget';
export * from './lib/dto/campaigns/campaignitemtargetdata';
export * from './lib/dto/campaigns/campaignitemtargetdetails';
export * from './lib/dto/campaigns/campaign-kpi-filter';
export * from './lib/dto/campaigns/campaignlistfilter';
export * from './lib/dto/campaigns/campaignsourcedata';
export * from './lib/dto/campaigns/campaignstatus';
export * from './lib/dto/campaigns/contenttype';
export * from './lib/dto/campaigns/createcampaignrequest';
export * from './lib/dto/campaigns/jobitemstatus';
export * from './lib/dto/configuration/configurationentry';
export * from './lib/dto/database/resultset';
export * from './lib/dto/documents/document';
export * from './lib/dto/documents/document-status';
export * from './lib/dto/facts/fact';
export * from './lib/dto/facts/factdata';
export * from './lib/dto/feedtransformers/feedtransformer';
export * from './lib/dto/feedtransformers/feedtransformerdata';
export * from './lib/dto/feedtransformers/feedtransformerfilter';
export * from './lib/dto/geo/geoinfo';
export * from './lib/dto/geo/geolistfilter';
export * from './lib/dto/jobs/clicks/click-data-type';
export * from './lib/dto/jobs/clicks/click-event-data';
export * from './lib/dto/jobs/clicks/click-type';
export * from './lib/dto/jobs/clicks/click-event-flags';
export * from './lib/dto/jobs/address';
export * from './lib/dto/jobs/company';
export * from './lib/dto/jobs/companydata';
export * from './lib/dto/jobs/jobdatastatus';
export * from './lib/dto/jobs/jobdetails';
export * from './lib/dto/jobs/jobfilter';
export * from './lib/dto/jobs/joblistitem';
export * from './lib/dto/jobs/jobstatusdetails';
export * from './lib/dto/jobs/jobstatusentry';
export * from './lib/dto/jobs/jobstatusfilter';
export * from './lib/dto/jobs/jobtype';
export * from './lib/dto/jobs/logtype';
export * from './lib/dto/jobs/validationentry';
export * from './lib/dto/performance/campaign-kpis';
export * from './lib/dto/performance/dayofweek';
export * from './lib/dto/performance/performanceentry';
export * from './lib/dto/performance/itemclickperformance';
export * from './lib/dto/performance/performancefilter';
export * from './lib/dto/performance/performancefilterv2';
export * from './lib/dto/performance/performanceitemgroup';
export * from './lib/dto/performance/performancewindowtype';
export * from './lib/dto/performance/reportFormat';
export * from './lib/dto/performance/performance-item-type';
export * from './lib/dto/performance/excellence-filter';
export * from './lib/dto/performance/excellence-weights';
export * from './lib/dto/performance/excellence-entry';
export * from './lib/dto/sources/feedformat';
export * from './lib/dto/sources/feedsource';
export * from './lib/dto/sources/feedsourcedata';
export * from './lib/dto/sources/sourcestatus';
export * from './lib/dto/sources/updatefrequency';
export * from './lib/dto/synonyms/synonym';
export * from './lib/dto/synonyms/synonymdata';
export * from './lib/dto/synonyms/synonymfilter';
export * from './lib/dto/warnings/campaign-warning';
export * from './lib/dto/warnings/campaign-warning-details';
export * from './lib/dto/warnings/campaign-warning-filter';

export * from './lib/guards/auth.guard';

export * from './lib/helpers/patch';

export * from './lib/interceptors/token.interceptor';

export * from './lib/services/analytics.service';
export * from './lib/services/auth.service';
export * from './lib/services/broker.service';
export * from './lib/services/campaign.service';
export * from './lib/services/campaign-item.service';
export * from './lib/services/campaign-kpi.service';
export * from './lib/services/campaign-warning.service';
export * from './lib/services/company.service';
export * from './lib/services/configuration.service';
export * from './lib/services/database.service';
export * from './lib/services/document.service';
export * from './lib/services/excellence.service';
export * from './lib/services/facts.service';
export * from './lib/services/feed-source.service';
export * from './lib/services/feed-transformer.service';
export * from './lib/services/geo.service';
export * from './lib/services/image-cache.service';
export * from './lib/services/job.service';
export * from './lib/services/job-ad.service';
export * from './lib/services/login.service';
export * from './lib/services/performance.service';
export * from './lib/services/rest.service';
export * from './lib/services/synonym.service';
export * from './lib/services/table.service';
export * from './lib/services/target.service';

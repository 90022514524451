import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ListFilter } from '../dto/listfilter';
import { FeedSource } from '../dto/sources/feedsource';
import { FeedSourceData } from '../dto/sources/feedsourcedata';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class FeedSourceService extends RestService<FeedSource, FeedSource, FeedSourceData, ListFilter> {

  constructor(@Inject('API_URL')apiurl: string, http: HttpClient) { 
    super(`${apiurl}/v1/feedsources`, http) 
  }
}

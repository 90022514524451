import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Campaign, CampaignListFilter, CampaignService, CampaignStatus, Patch } from 'mamgo-api';
import { Paging } from '../../helpers/paging';
import { Column } from '../../models/column';
import { CampaignCreatorComponent } from '../campaign-creator/campaign-creator.component';
import {Errors} from "../../helpers/Errors";
import {Tables} from "../../helpers/tables";

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {
  Paging=Paging;
  Tables=Tables;

  page: number=1;
  total: number=0;
  filter: CampaignListFilter={
    count: 25,
    continue: 0
  };

  campaigns: MatTableDataSource<Campaign>=new MatTableDataSource<Campaign>();

  columns: Column[]=[{
    name: "name",
    display: "Name"
  }, {
    name: "budget",
    display: "Budget"
  }, {
    name: "status",
    display: "Status"
  }, {
    name: "_actions",
    display: ""
  }];

  constructor(private campaignService: CampaignService, private snackBar: MatSnackBar, private dialogservice: MatDialog) {
  }

  ngOnInit(): void {
    this.loadCampaigns();
  }

  loadCampaigns(): void {
    this.campaignService.list(this.filter).toPromise().then(page => {
      this.total = page.total;
      this.page = this.filter.continue / this.filter.count;
      this.campaigns.data = page.result;
    }).catch(error => {
      this.snackBar.open(Errors.getError(error));
    });
  }

  changeStatus(campaignId: number, status: CampaignStatus): void {
    this.campaignService.patch(campaignId, [
      Patch.replace("status", status)
    ]).toPromise().then(c=>{
      let index=this.campaigns.data.findIndex(cmp=>cmp.id===campaignId);
      if(index>=0) {
        this.campaigns.data[index]=c;
        this.campaigns.data=this.campaigns.data;
      }
    }).catch(e=>{
      this.snackBar.open(e.error.text);
    });
  }

  getDisplayedColumns(): string[] {
    return this.columns.map<string>(c=>c.name);
  }

  createCampaign(): void {
    this.dialogservice.open(CampaignCreatorComponent).afterClosed().subscribe(e=>{
      this.loadCampaigns();
    });
  }

  onPageChanged(pageIndex: number, pageSize: number): void {
    this.filter.continue=pageIndex*pageSize;
    this.filter.count=pageSize;
    this.loadCampaigns();
  }
}

import { Column } from '../models/column';

/**
 * operations used for table interactions
 */
export class Tables {

    /**
     * extracts names of a column descriptor list
     * @param columns columns of which to extract names
     */
    public static getColumns(columns: Column[]): string[] {
        return columns.map<string>(c=>c.name);
    }

    /**
     * extracts data from a host property
     * @param host host of which to extract data
     * @param path path to data property
     */
    public static getData(host: any, path: string): any {
        if(!host)
            return null;

        let current=host;
        let items: string[]=path.split(".");
        for(let item of items) {
            current=current[item];
            if(!current)
                return null;
        }

        return current;
    }
}
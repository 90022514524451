import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule } from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './business/dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SourcesComponent } from './business/sources/sources.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CampaignsComponent } from './business/campaigns/campaigns.component';
import {TokenInterceptor} from 'mamgo-api';
import { CampaignEditorComponent } from './business/campaign-editor/campaign-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSliderModule} from '@angular/material/slider';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import { JobsComponent } from './system/jobs/jobs.component';
import { ConfigurationComponent } from './dev/configuration/configuration.component';
import { DebounceDirective } from './directives/debounce.directive';
import { DatabaseComponent } from './dev/database/database.component';
import { BrokersComponent } from './system/brokers/brokers.component';
import {MatButtonModule} from '@angular/material/button';
import { CampaignCreatorComponent } from './business/campaign-creator/campaign-creator.component';
import { MatDialogModule} from '@angular/material/dialog';
import { JobDetailsComponent } from './system/job-details/job-details.component';
import {MatChipsModule} from '@angular/material/chips';
import { JobStatusLogComponent } from './system/job-status-log/job-status-log.component';
import { MamgoComponentsModule } from 'mamgo-components';
import { SourceCreatorComponent } from './business/source-creator/source-creator.component';
import { SourceEditorComponent } from './business/source-editor/source-editor.component';
import { AnalysisComponent } from './business/analysis/analysis.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { IsoDateAdapter } from './adapters/isodateadapter';
import { FactsComponent } from './system/facts/facts.component';
import { SynonymsComponent } from './business/synonyms/synonyms.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { ReportComponent } from './business/report/report.component';
import { CampaignViewerComponent } from './business/campaign-viewer/campaign-viewer.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SourcesComponent,
    CampaignsComponent,
    CampaignEditorComponent,
    JobsComponent,
    ConfigurationComponent,
    DebounceDirective,
    DatabaseComponent,
    BrokersComponent,
    CampaignCreatorComponent,
    JobDetailsComponent,
    JobStatusLogComponent,
    SourceCreatorComponent,
    SourceEditorComponent,
    AnalysisComponent,
    FactsComponent,
    SynonymsComponent,
    ReportComponent,
    CampaignViewerComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatChipsModule,
    MatSliderModule,
    MatTooltipModule,

    MamgoComponentsModule,
    NgxChartsModule
  ],
  providers: [
    { provide: "AUTH_URL", useValue: environment.AUTH_URL },
    { provide: "API_URL", useValue: environment.API_URL },
    { provide: "CLIENT_ID", useValue: environment.CLIENT_ID },
    { provide: "CLIENT_SECRET", useValue: environment.CLIENT_SECRET },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    { provide: DateAdapter, useClass: IsoDateAdapter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

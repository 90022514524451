/**
 * type of click
 */
export enum ClickType {

  /**
   * visit to the page
   */
  Visit=0,

  /**
   * click on application button
   */
  ApplicationButton=1,

  /**
   * a real application
   */
  Application=2,

  /**
   * paid click on broker page which did not convert to a landing page hit yet
   */
  Broker=7,

  /**
   * conversion of broker click
   * when this is set it should hopefully match the sum of visits
   */
  Conversion=9,

  /**
   * display of ad on broker page
   */
  Impressions=10,

  /**
   * sample for a unit of time to measure how long an item was posted under certain configurations
   */
  TimeSample=11,

  /**
   * visit registered by external script
   */
  ExternVisit=12
}

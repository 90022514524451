<div class="pagecontent">
    <div class="titlecontainer">
        <h1>Facts</h1>
    </div>
    <div class="container mat-elevation-z8">
        <table mat-table [dataSource]="facts" >
            <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
                <th mat-header-cell *matHeaderCellDef>{{column.display}}</th>
                <td mat-cell *matCellDef="let element">
                        <span>{{Tables.getData(element, column.name) | display:column.format}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="Tables.getColumns(columns)"></tr>
            <tr mat-row *matRowDef="let row; columns: Tables.getColumns(columns);"></tr>
        </table>
        <mat-paginator [pageIndex]="page" [length]="total" [pageSizeOptions]="Paging.pageSizes" (page)="onPageChanged($event.pageIndex, $event.pageSize)"></mat-paginator>
    </div>
</div>
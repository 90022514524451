import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'mamgo-api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('userName') userName: ElementRef;
  @ViewChild('password') password: ElementRef;

  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(private route: ActivatedRoute, private router: Router, private loginservice: LoginService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit(): void {
    this.submitted = true;

    this.loading = true;
    this.loginservice.login(this.userName.nativeElement.value, this.password.nativeElement.value)
      .then(data => {
        this.router.navigate([this.returnUrl]);
      }).catch(e=>{
        this.snackBar.open(e.error.text);
        this.loading = false;
      });
  }
}
